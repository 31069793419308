import {CanActivate, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {
  constructor(private cookieService: CookieService,
              private router: Router) {
  }

  canActivate() {
    const authValidation = this.cookieService.get('Token');

    if (!authValidation) {
      return true;
    }

    this.router.navigate(['home']);

    return false;
  }
}
