import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {PurchaseOrdersService} from '../../services/purchase-orders.service';
import {ConfirmActionDialogComponent} from '../confirm-action-dialog/confirm-action-dialog.component';
import {ActionCompletionDialogComponent} from '../action-completion-dialog/action-completion-dialog.component';
import {environment} from '../../../environments/environment';
import {CommonService} from '../../services/common.service';
import {LoadingService} from '../../services/loading.service';

@Component({
  selector: 'app-assign-and-detail-modal',
  templateUrl: './assign-and-detail-modal.component.html',
  styleUrls: ['./assign-and-detail-modal.component.scss']
})
export class AssignAndDetailModalComponent implements OnInit {
  public type: number;
  public dataFields: any;
  public title: string;
  public placeHolders: string[];
  public userSalePoint: any;
  public selectedCommand: any;
  public imageFilesSource: any[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private commonService: CommonService,
              public purchaseOrdersService: PurchaseOrdersService,
              public loadingService: LoadingService,
              public dialogRef: MatDialogRef<AssignAndDetailModalComponent>, private dialog: MatDialog) {
    this.type = this.data.type;
    this.dataFields = [];
    this.imageFilesSource = [];
    this.userSalePoint = this.data.userSalePoint;
    this.selectedCommand = null;
  }

  ngOnInit() {
    let request;
    this.title = this.type === 0 ? 'Πληροφορίες ψυγείου' : 'Ανάθεση αίτησης επιστροφής';

    request = this.type === 0 ? {url: 'refrigerator', filter: `serial_number=${this.data.serial_number}`, options: false} : {
      url: 'return_command/web',
      filter: `status_id=6`,
      options: false
    };
    if (this.type === 0 && !this.data.serial_number) {
      this.noInfo();
    } else {
      this.purchaseOrdersService.getList(request.url, request.filter, request.options).then(data => {

        if (this.type === 0) {
          if (data.length > 0) {
            const keys = Object.keys(data[0]);
            this.placeHolders = ['Αριθμός Ψυγείου', 'Σειριακός Αριθμός Ψυγείου', 'Κωδικός Είδους', 'Είδος', 'Παρτίδα',
              'Εικαστικό', 'Καθαρό Βάρος', 'Κωδικός Μοντέλου', 'Μόντέλο', 'Ποιότητα',
              'Κατασκευαστής', 'Κωδικός Εξοπλισμού', 'Σημείο Πώλησης'];
            keys.forEach(key => {
              if (key !== 'batch_id' && key !== 'eikastiko_id' && key !== 'quality_id' &&
                key !== 'manufacturer_id' && key !== 'sale_point_id' &&
                key !== 'sale_point_customer_code' && key !== 'sales_group_description') {
                const model = {
                  key,
                  value: data[0][key]
                };
                this.dataFields.push(model);
              }
            });
          } else {
            this.noInfo();
          }
        } else {
          this.dataFields = data;
        }
      });
    }
  }

  public noInfo(): void {
    this.loadingService.show();

    try {
      const filter = `id=${this.data.refrigeratorId}`;
      this.commonService.getListWithParameters(`return_command/web/details/files?id=${this.data.refrigeratorId}`, null).
      then((files: any) => {
        for (const file of files) {
          const imageFile = `${environment.apiUrl}files?name=${file.file_name}&model=return_command`;
          this.imageFilesSource.push(imageFile);
        }
      });
    } catch (e) {
      console.log(e);
      this.imageFilesSource = [];
    } finally {
      this.loadingService.hide();
    }
  }

  public commandSelectionChange(command) {
    this.selectedCommand = command;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public confirmAssignment(): void {
    this.dialog.open(ConfirmActionDialogComponent, {
      data: {
        confirmFunction: this.assignCommandToUser,
        message: `Είστε σίγουροι ότι θέλετε να αναθέσετε ως παραλήπτη: ${this.userSalePoint[0].customer_name}  για την εντολή επιστροφής με αριθμό : ${this.selectedCommand.id}?`,
        title: 'Ανάθεση εντολής',
        itemToExecute: {salePointId: this.userSalePoint[0].id, commandId: this.selectedCommand.id}
      }
    });
  }

  public isDisabled(): boolean {
    return this.selectedCommand === null;
  }

  public async assignCommandToUser(object: any): Promise<void> {
    try {
      await this.purchaseOrdersService.updateReceiptCommand(`return_command/web/routing/${object.commandId}`,
        {recipient_sale_point_id: object.salePointId});
      this.dialog.open(ActionCompletionDialogComponent, {
        data: {
          buttonText: 'Πλοήγηση στις επιστροφές ψυγείων',
          redirectURL: `returns`
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
}
