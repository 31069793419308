import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {PurchaseOrdersService} from '../../services/purchase-orders.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LoadingService} from '../../services/loading.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatCheckboxChange, MatStepper} from '@angular/material';
import {IOrderDetails} from '../../models/models';
import * as moment from 'moment';
import {ActionCompletionDialogComponent} from '../action-completion-dialog/action-completion-dialog.component';

@Component({
  selector: 'app-create-unloading-request',
  templateUrl: './create-unloading-request.component.html',
  styleUrls: ['./create-unloading-request.component.scss']
})
export class CreateUnloadingRequestComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('stepper', {static: false}) stepper: MatStepper;

  public isLinear: boolean;
  public canEdit: boolean;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[];
  public selection: SelectionModel<any>;
  public orderSelection: SelectionModel<any>;
  public detailsDataSource = new MatTableDataSource<IOrderDetails>();
  public title: string;
  public step1Title: string;
  public step2Title: string;
  public step3Title: string;
  public orderDisplayedColumns: string[];
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public minDate: Date;
  private vendor: any;
  private selectedRecipient: any;
  private selectedSalePoint: any;
  public deliveryPointsRecipients: any;
  private requestDetails: any;

  constructor(private commonService: CommonService,
              private purchaseOrdersService: PurchaseOrdersService,
              private dialog: MatDialog, private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<CreateUnloadingRequestComponent>,
              private loadingService: LoadingService,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.isLinear = false;
    this.canEdit = false;
    this.minDate = new Date();
    if (this.data.type === 1 && this.data.orderType === 1) {
      this.title = `Δημιουργία νέας εντολής παραλαβής για τον αριθμό παραγγελίας ${this.data.purchaseDocument}`;
    } else if (this.data.type === 2 && this.data.orderType === 1) {
      this.title = `Δημιουργία νέας εντολής παραλαβής για τον αριθμό αίτησης ${this.data.order_id}`;
    } else {
      this.title = `Μετατροπή της εντολής φόρτωσης με αριθμό ${this.data.order_id}`;
    }

    this.step1Title = this.data.type === 1 ? 'Επιλέξτε κατηγορία και ημερομηνία' : 'Επιλέξτε ημερομηνία';
    this.step2Title = this.data.type === 1 ? 'Επιλέξτε προορισμό' : 'Επιλέξτε αποστολέα';
    this.step3Title = 'Επιλέξτε Ποσότητα';
    this.dataSource = new MatTableDataSource<any>(this.data.users);

    this.displayedColumns = ['select', 'name', 'role_name', 'status_name'];

    this.selection = new SelectionModel<any>(true, []);
    this.orderSelection = new SelectionModel<any>(true, []);

    this.displayedColumns = this.data.type === 1 ? ['select', 'customer_name', 'vat_number', 'city',
      'address', 'phone_number1', 'type_name'] : ['select', 'name', 'role_name', 'status_name'];


    this.requestDetails = this.data.type === 1 ? JSON.parse(localStorage.getItem('requestDetails')) : JSON.parse(localStorage.getItem('orderDetails'));
    this.dialogRef.updateSize('500');
  }

  ngOnInit() {
   if (this.data.orderType === 2) {
     this.requestDetails =  JSON.parse(localStorage.getItem('orderDetails'));
   }
    if (this.data.type === 1 && this.data.orderType === 1) {
      this.initRecipients();
      this.initVendor();
      this.firstFormGroup = this.formBuilder.group({
        role: ['', Validators.required],
        sent_date: ['', Validators.required]
      });
    } else {
      this.firstFormGroup = this.formBuilder.group({
        sent_date: ['', Validators.required]
      });
    }


    if (this.data.orderType === 2) {
      console.log(this.requestDetails);
      console.log(`data: ${this.requestDetails.desired_delivery_date}`);
      this.firstFormGroup.controls.sent_date.setValue(this.requestDetails.desired_delivery_date);
    }
   this.secondFormGroup = new FormGroup({});

    this.thirdFormGroup = new FormGroup({});
  }

  private async initVendor() {
    const result = await this.commonService.getListWithParameters(`purchase_orders`, `purchasing_document=${this.data.purchaseDocument}`);
    this.vendor = result[0];
  }

  private async initRecipients() {
    try {
      this.loadingService.show();
      const data = await this.purchaseOrdersService.getList('sale_point_category');
      if (this.data.type === 1) {
        this.deliveryPointsRecipients = data.filter(recipient => recipient.id !== 1 && recipient.id !== 5);
      } else {
        this.deliveryPointsRecipients = data.filter(recipient => recipient.id === 1 || recipient.id === 4);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      this.loadingService.hide();
    }
  }

  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  selectRow(row: any) {
    this.selection.clear();
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public isAllOrdersSelected(): boolean {
    const numSelected = this.orderSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  public masterOrderToggle(): void {
    this.isAllSelected() ? this.orderSelection.clear() : this.dataSource.data.forEach((row) => this.orderSelection.select(row));
  }

  isFormValid(): boolean {
    return this.selection.selected.length === 0;
  }

  async next() {
    let result: any;
    if (this.stepper.selectedIndex === 0) {
      this.loadingService.show();
      // Νεα Απο παραγγελία
      if (this.data.type === 1) {
        let filter = '';
        if (this.data.orderType === 1) {
          this.selectedRecipient = this.firstFormGroup.get('role').value;
          filter = this.selectedRecipient.id === 1 ? `(account_group_id <> 3000 AND account_group_id <> 3009) AND (sales_organization_id = 1000 OR sales_organization_id = 2000)` : `category_id=${this.selectedRecipient.id}`;
        } else {
          filter = `id=${this.requestDetails.sale_point_id}`;
        }
        result = await this.purchaseOrdersService.getList('sale_point', filter);
        this.dataSource = new MatTableDataSource<any>(result);
      } else {
        // Νέα απο άιτηση
        this.dataSource = new MatTableDataSource<any>(this.data.users);
      }

      this.dataSource.paginator = this.commonService.setPaginatorTexts(this.paginator);
      this.dataSource.sort = this.sort;

      if (this.data.orderType === 2 && this.data.type === 2) {
        // Μετατροπή αίτησης
        const row = this.dataSource.data.filter(d => d.id === this.requestDetails.user_id);
        this.dataSource.filter = this.requestDetails.user_id.toString();
        this.dataSource.data.forEach((r) => this.selection.select(r));
      } else if (this.data.orderType === 2 && this.data.type === 1) {
        this.dataSource.data.forEach((r) => this.selection.select(r));
      }
      this.loadingService.hide();
      this.stepper.next();

    } else if (this.stepper.selectedIndex === 1) {
      if (this.data.orderType === 1) {
        // Νεα εντολή
        this.orderDisplayedColumns = ['select', 'material_id', 'material_description', 'quantity_ordered', 'loaded_quantity', 'loading_quantity'];
        this.selectedSalePoint = this.selection.selected;

        if (this.data.type === 1) {
          // Παραγγελία
          this.detailsDataSource.data = this.data.details;
        } else {
          // Αίτηση
          this.data.details.forEach(d => {
            d.loaded_quantity = d.loaded_quantity === null ? 0 : d.loaded_quantity;
          });
          this.data.details.forEach(d => {
            d.quantity_ordered = d.total_quantity;
            d.quantity_ordered = d.quantity;
          });

          this.detailsDataSource.data = this.data.details;
        }
        this.stepper.next();
      } else {
        // Μετατροπή
        this.orderDisplayedColumns = ['select', 'material_id', 'material_description', 'total_quantity', 'loaded_quantity', 'receiving_quantity'];

        const data = await this.purchaseOrdersService.getList('loading_command/web/details', `command_id=${this.data.order_id}`, true);
        data.value.forEach(d => {
          d.loaded_quantity = d.loaded_quantity === null ? 0 : d.loaded_quantity;
        });
        data.value.forEach(d => {
          d.quantity_left = d.total_quantity - d.loaded_quantity;
        });
        this.detailsDataSource.data = data.value;
        this.stepper.next();
      }
    }
  }

  isFirsFormGroupValid() {
    return !this.firstFormGroup.valid;
  }

  previous() {
    if (this.stepper.selectedIndex === 1) {
      this.stepper.previous();
    } else if (this.stepper.selectedIndex === 2) {
      this.stepper.previous();
    }
  }

  isSecondFormGroupValid() {
    return this.selection.selected.length === 0;
  }

  public updateData(index: number, event: any): void {
    const value = event.target.value;
    if (index !== -1) {
      this.detailsDataSource.data[index].loading_quantity = value ? value : '';
    }
  }

  isUnLoadingValid() {
    let valid = true;
    this.orderSelection.selected.forEach(selectedItem => {
      const quantity = selectedItem.loading_quantity;
      if (quantity === null || !quantity) {
        valid = false;
      }
    });

    return !(valid && this.orderSelection.selected.length > 0);
  }

  selectionChanged($event: MatCheckboxChange, row) {
    if ($event.checked === false) {
      return this.selection.deselect(row);
    } else {
      return this.selection.select(row);
    }
  }

  async submitUnLoading() {
    this.loadingService.show();
    let unloadingModel: any;
    if (this.data.orderType === 1) {
      console.log(this.vendor);
      unloadingModel = {
        user_id: this.data.type === 1 ? this.vendor.vendor_user_id : this.selectedSalePoint[0].id,
        order_id: this.data.order_id,
        order_type_id: 2,
        sale_point_id: this.data.type === 1 ? this.selectedSalePoint[0].id : this.requestDetails.sale_point_id,
        desired_delivery_date: moment(this.firstFormGroup.get('sent_date').value).format('YYYY-MM-DD'),
        command_type: 1,
        command_id: null,
        materials: []
      };
    } else {
      unloadingModel = {
        user_id: this.requestDetails.user_id,
        order_id: this.data.order_id,
        order_type_id: 2,
        sale_point_id: this.requestDetails.sale_point_id,
        desired_delivery_date: moment(this.firstFormGroup.get('sent_date').value).format('YYYY-MM-DD'),
        command_type: 1,
        command_id: this.data.order_id,
        materials: []
      };
    }

    this.orderSelection.selected.forEach(selectedItem => {
      console.log(selectedItem);
      const model = {
        material_id: selectedItem.material_id,
        total_quantity: selectedItem.loading_quantity
      };
      unloadingModel.materials.push(model);
    });

    try {
      await  this.purchaseOrdersService.storeLoadingCommand('unloading_command', unloadingModel);
      this.loadingService.hide();
      this.dialogRef.close();
      this.dialog.open(ActionCompletionDialogComponent, {
            data: {
              buttonText: 'Πλοήγηση στις παραλαβές',
              redirectURL: `receipt_orders`
            }
          });
    } catch (e) {
      console.log(e.message);
    }
  }

  hasDate() {
    return this.data.orderType === 2;
  }
}
