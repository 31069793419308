import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {CommonService} from '../../services/common.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PurchaseOrdersService} from '../../services/purchase-orders.service';
import {MatCheckboxChange, MatStepper} from '@angular/material';
import {LoadingService} from '../../services/loading.service';
import {IOrderDetails, ISalePoint} from '../../models/models';
import * as moment from 'moment';
import {ActionCompletionDialogComponent} from '../action-completion-dialog/action-completion-dialog.component';

@Component({
  selector: 'app-create-loading-request',
  templateUrl: './create-loading-request.component.html',
  styleUrls: ['./create-loading-request.component.scss']
})
export class CreateLoadingRequestComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  public isLinear: true;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public selectRecipients: FormGroup;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[];
  public title: string;
  public deliveryPointsRecipients: any;
  public selection: SelectionModel<any>;
  public orderSelection: SelectionModel<IOrderDetails>;
  public selectedRecipient: any;
  public step1Title: string;
  public step2Title: string;
  private selectedSalePoint: any;
  public poDetailsDataSource = new MatTableDataSource<IOrderDetails>();
  public loadingOrderDetailsColumns: string[] = ['select', 'material_id', 'material_description', 'quantity_ordered', 'loaded_quantity', 'loading_quantity'];
  private requestDetails: any;
  private vendor: any;

  constructor(private dialogRef: MatDialogRef<CreateLoadingRequestComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private commonService: CommonService, private formBuilder: FormBuilder, private dialog: MatDialog,
              private purchaseOrdersService: PurchaseOrdersService, public loadingService: LoadingService) {

    const type = this.data.type === 1 ? `Αριθμό Παρραγγελίας ${this.data.purchaseDocument}` : `Αριθμό Αίτησης ${this.data.order_id}`;
    this.title = `Νέα Εντολή Φόρτωσης για τον ${type}`;

    this.step1Title = this.data.type === 1 ? `Επιλέξτε κατηγορία παραλήπτη και ημερομηνία` : `Επιλέξτε κατηγορία αποστολέα και ημερομηνία`;
    this.step2Title = this.data.type === 1 ? `Επιλέξτε παραλήπτη` : `Επιλέξτε αποστολέα`;

    this.displayedColumns = this.data.type === 1 ? ['select', 'customer_name', 'vat_number', 'city',
      'address', 'phone_number1', 'type_name'] : ['select', 'name', 'role_name', 'status_name'];

    this.selection = new SelectionModel<any>(true, []);
    this.orderSelection = new SelectionModel<IOrderDetails>(true, []);

    this.requestDetails = JSON.parse(localStorage.getItem('requestDetails'));
    this.dialogRef.updateSize('500');
  }

  ngOnInit() {
    if (this.data.type === 1) {
      this.initRecipients();
      this.initVendor();
      this.selectRecipients = this.formBuilder.group({
        recipient: ['', Validators.required],
        delivery_date: ['', Validators.required]
      });
    } else {
      this.selectRecipients = this.formBuilder.group({
        delivery_date: ['', Validators.required]
      });
    }
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  private async initVendor() {
    const result = await this.commonService.getListWithParameters(`purchase_orders`, `purchasing_document=${this.data.purchaseDocument}`);
    this.vendor = result[0];
  }

  private async initRecipients() {
    try {
      this.loadingService.show();
      const data = await this.purchaseOrdersService.getList('sale_point_category');
      if (this.data.type === 1) {
        this.deliveryPointsRecipients = data.filter(recipient => recipient.id !== 1 && recipient.id !== 5);
      } else {
        this.deliveryPointsRecipients = data.filter(recipient => recipient.id === 1 || recipient.id === 4);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      this.loadingService.hide();
    }
  }

  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private initPaginatorAndSort() {
    this.dataSource.paginator = this.commonService.setPaginatorTexts(this.paginator);
    this.dataSource.sort = this.sort;
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public isAllOrdersSelected(): boolean {
    const numSelected = this.orderSelection.selected.length;
    const numRows = this.poDetailsDataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  public masterOrderToggle(): void {
    this.isAllSelected() ? this.orderSelection.clear() : this.poDetailsDataSource.data.forEach((row) => this.orderSelection.select(row));
  }

  selectRow(row: any) {
    this.selection.clear();
  }

  isRecipientFormValid(): boolean {
    return !this.selectRecipients.valid;
  }

  public async next() {
    console.log(`index: ${this.stepper.selectedIndex}`);
    if (this.stepper.selectedIndex === 0) {
      if (this.data.type === 1) {
        this.selectedRecipient = this.selectRecipients.get('recipient').value;
        const filter = this.selectedRecipient.id === 1 ? `(account_group_id <> 3000 AND account_group_id <> 3009) AND (sales_organization_id = 1000 OR sales_organization_id = 2000)` : `category_id=${this.selectedRecipient.id}`;
        this.loadingService.show();
        const result = await this.purchaseOrdersService.getList('sale_point', filter);
        console.log(result);
        this.dataSource = new MatTableDataSource<any>(result);
        this.initPaginatorAndSort();
        this.loadingService.hide();
      } else {
        this.dataSource = new MatTableDataSource<any>(this.data.users);
        this.initPaginatorAndSort();
      }
      this.stepper.next();
    } else if (this.stepper.selectedIndex === 1) {
      this.selectedSalePoint = this.selection.selected;
      console.log(this.selectedSalePoint);
      if (this.data.type === 1) {
        console.log('παραγγελια');
        this.loadingService.show();
        try {
          const data = await this.purchaseOrdersService.getList('po_details', `po_id=${this.data.order_id}`);
          data.forEach(d => {
            d.loaded_quantity = d.loaded_quantity === null ? 0 : d.loaded_quantity;
          });
          this.poDetailsDataSource.data = data;
          this.stepper.next();
        } catch (e) {
          console.log(e.message);
        } finally {
          this.loadingService.hide();
        }
      } else {
        console.log('Αίτηση');
        try {
          this.loadingService.show();
          const data = await this.purchaseOrdersService.getList('request/web/details', `request_id=${this.data.order_id} AND (sales_inspector_action_id <> 2 OR sales_inspector_action_id is null)`);
          data.forEach(d => {
            d.loaded_quantity = d.loaded_quantity === null ? 0 : d.loaded_quantity;
          });
          data.forEach(d => {
            d.quantity_ordered = d.quantity;
          });
          this.poDetailsDataSource.data = data;
          this.stepper.next();
        } catch (e) {
          console.log(e.message);
        } finally {
          this.loadingService.hide();
        }
      }
    }
  }

  checkboxLabel(row?: IOrderDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.orderSelection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  public previous() {
    if (this.stepper.selectedIndex === 1) {
      this.selection.clear();
      this.stepper.previous();
    } else if (this.stepper.selectedIndex === 2) {
      this.stepper.previous();
    }
  }

  selectionChanged($event: MatCheckboxChange, row) {
    if ($event.checked === false) {
      return this.selection.deselect(row);
    } else {
      return this.selection.select(row);
    }
  }

  public isLoadingValid(): boolean {
    let valid = true;
    this.orderSelection.selected.forEach(selectedItem => {
      const quantity = selectedItem.loading_quantity;
      if (quantity === null || !quantity) {
        valid = false;
      }
    });

    return !(valid && this.orderSelection.selected.length > 0);
  }

  public updateData(index: number, event: any): void {
    const value = event.target.value;
    if (index !== -1) {
      this.poDetailsDataSource.data[index].loading_quantity = value ? value : '';
    }
  }

  isRecipientsValid(): boolean {
    return this.selection.selected.length === 0;
  }


  public async submitLoading() {
    this.loadingService.show();

    const loadingModel = {
      user_id: this.data.type === 1 ? this.vendor.vendor_user_id : this.selectedSalePoint[0].id,
      order_id: this.data.order_id,
      order_type_id: this.data.type,
      sale_point_id: this.data.type === 1 ? this.selectedSalePoint[0].id : this.requestDetails.sale_point_id,
      desired_delivery_date: moment(this.selectRecipients.get('delivery_date').value).format('YYYY-MM-DD'),
      command_type: 0,
      command_id: null,
      materials: []
    };

    this.orderSelection.selected.forEach(selectedItem => {
      const model = {
        material_id: selectedItem.material_id,
        total_quantity: selectedItem.loading_quantity
      };
      loadingModel.materials.push(model);
    });

    try {
      await this.purchaseOrdersService.storeLoadingCommand('loading_command', loadingModel);
      this.loadingService.hide();
      this.dialogRef.close();

      this.dialog.open(ActionCompletionDialogComponent, {
        data: {
          buttonText: 'Πλοήγηση στις εντολές φόρτωσης',
          redirectURL: `loading_orders`
        }
      });
    } catch (e) {
      this.dialogRef.close();
    }
  }
}
