import {Component, Inject, OnInit} from '@angular/core';
import {LoadingService} from '../../services/loading.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})

export class ErrorDialogComponent implements OnInit {
  public mode: string;
  public message: string;

  constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>,
              private loadingService: LoadingService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loadingService.hide();

    this.mode = this.data.mode;
    this.message = `Παρουσιάστηκε κάποιο σφάλμα κατά την εκτέλεση κάποιας διεργάσιας του συστήματος. ${this.data.error.message}`;
  }

  ngOnInit() {
    this.dialogRef.updateSize('600px');

    this.dialogRef.disableClose = true;
  }

  public closeDialog(): void {
    this.dialogRef.close();

    if (this.mode === 'global') {
      window.location.reload();
    }
  }
}
