import {CommonService} from './common.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Socket} from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})

export class SalePointsService {
  public insertedSalePoints: Observable<any>;
  private serviceModel: string;

  constructor(private commonService: CommonService,
              private httpClient: HttpClient,
              private socket: Socket) {
    this.insertedSalePoints = this.socket.fromEvent<any>('insertSalePoint');

    this.serviceModel = 'sale_point';
  }

  public getSalePoint(id: number): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(`${this.serviceModel}?first=true&filter=id=${id}`).subscribe((result) => {
        resolve(result);
      });
    });
  }

  public getUserSalePoints(filter: string): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(`${this.serviceModel}?filter=${filter}`).subscribe((result) => {
          resolve(result);
        });
    });
  }

  public getUserSalePointsCount(filter: string): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(`${this.serviceModel}?filter=${filter}&select=COUNT(*) AS count`)
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  public initializeSalePoint(salePoint: any): any[] {
    return [
      {
        title: 'Στοιχεία Σημείου Πώλησης',
        values: [
          {
            name: 'Επωνυμία',
            value: this.commonService.setValue(salePoint.customer_name)
          },
          {
            name: 'Α.Φ.Μ.',
            value: this.commonService.setValue(salePoint.vat_number)
          },
          {
            name: 'Βασική Ομάδα Πελατών',
            value: this.commonService.setValue(salePoint.account_group_name)
          },
          {
            name: 'Περιφερειακός Διευθυντής',
            value: this.commonService.setValue(salePoint.regional_name)
          },
          {
            name: 'Επιθεωρητής',
            value: this.commonService.setValue(salePoint.inspector_name)
          },
          {
            name: 'Δρομολόγιο',
            value: this.commonService.setValue(salePoint.sales_group_name)
          },
          {
            name: 'Ομάδα Πελατών 1',
            value: this.commonService.setValue(salePoint.customer_group1_name)
          },
          {
            name: 'Ομάδα Πελατών 2',
            value: this.commonService.setValue(salePoint.customer_group2_name)
          },
          {
            name: 'Κωδικός Πελάτη',
            value: this.commonService.setValue(salePoint.customer_code)
          },
          {
            name: 'Επωνυμία Πληρωτή',
            value: this.commonService.setValue(salePoint.plirotis_name)
          },
          {
            name: 'Γλώσσσα',
            value: this.commonService.setValue(salePoint.language_name)
          },
          {
            name: 'Είδος Σημείου Πώλησης',
            value: this.commonService.setValue(salePoint.type_name)
          },
          {
            name: 'Επωνυμία 2',
            value: this.commonService.setValue(salePoint.name3)
          },
          {
            name: 'Προμηθευτής',
            value: this.commonService.setValue(salePoint.supplier_name)
          },
          {
            name: 'Κωδικός Εφορίας',
            value: this.commonService.setValue(salePoint.taxcode)
          },
          {
            phantom: true
          }
        ]
      },
      {
        title: 'Στοιχεία Επικοινωνίας',
        values: [
          {
            name: 'Διεύθυνση',
            value: this.commonService.setValue(salePoint.address)
          },
          {
            name: 'Τ.Κ.',
            value: this.commonService.setValue(salePoint.postal_code)
          },
          {
            name: 'Πόλη',
            value: this.commonService.setValue(salePoint.city)
          },
          {
            name: 'Χώρα',
            value: this.commonService.setValue(salePoint.country_name)
          },
          {
            name: 'Περιοχή',
            value: this.commonService.setValue(salePoint.region_description)
          },
          {
            name: 'Τηλέφωνο 1',
            value: this.commonService.setValue(salePoint.phone_number1)
          },
          {
            name: 'Τηλέφωνο 2',
            value: this.commonService.setValue(salePoint.phone_number2)
          },
          {
            name: 'Κινητό',
            value: this.commonService.setValue(salePoint.mobile)
          },
          {
            name: 'Fax',
            value: this.commonService.setValue(salePoint.fax)
          },
          {
            name: 'Email',
            value: this.commonService.setValue(salePoint.email)
          },
          {
            phantom: true
          },
          {
            phantom: true
          }
        ]
      }
    ];
  }
}
