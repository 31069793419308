import {Column, TableConfig, Types} from '../table/table-config';
import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class TableFiltersService {
  constructor() {
  }

  public getFilter(config: TableConfig): any {
    let filter = '';

    for (let i = 0; i < config.columns.length; i++) {
      if (config.columns[i].type === Types.customSelect && config.columns[i].customSelected) {
        filter = `${filter === '' ? filter : `${filter} AND `}(`;

        for (let y = 0; y < config.columns[i].customSelected.length; y++) {
          filter = `${filter}${y !== 0 ? ' OR ' : ''}${config.columns[i].customSelected[y].filter}`;
        }

        filter = `${filter})`;
      } else if ((config.columns[i].type === Types.date || config.columns[i].type === Types.dateTime) &&
        (config.columns[i].fromValue || config.columns[i].toValue)) {
        filter = `${filter === '' ? filter : `${filter} AND `}(${config.columns[i].name} BETWEEN '${config.columns[i].
          fromValue ? moment(config.columns[i].fromValue).format('YYYY-MM-DD') :
          moment().format('YYYY-MM-DD')}' AND '${config.columns[i].toValue ?
          moment(config.columns[i].toValue).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}')`;
      } else if (config.columns[i].type === Types.multipleSelect && config.columns[i].selected &&
        config.columns[i].selected.length > 0) {
        filter = `${filter === '' ? filter : `${filter} AND `}(`;

        for (let y = 0; y < config.columns[i].selected.length; y++) {
          const columnName = config.columns[i].name.split('_names');

          filter = `${filter}${y !== 0 ? ' OR ' : ''}(${columnName[0]}_ids LIKE '${config.columns[i].selected[y].
            id},' OR ${columnName[0]}_ids LIKE ',${config.columns[i].selected[y].id}' OR ${
            columnName[0]}_ids LIKE '%,${config.columns[i].selected[y].id},%' OR ${columnName[0]}_ids=${config.
            columns[i].selected[y].id})`;
        }

        filter = `${filter})`;
      } else if ((config.columns[i].type === Types.number) &&
        (config.columns[i].fromValue || config.columns[i].toValue)) {
        filter = `${filter === '' ? filter : `${filter} AND `}(${config.columns[i].name} BETWEEN ${config.columns[i].
          fromValue !== undefined ? config.columns[i].fromValue : 0} AND ${config.columns[i].toValue !== undefined ?
          config.columns[i].toValue : 0})`;
      } else if (config.columns[i].type === Types.select && config.columns[i].selected &&
        config.columns[i].selected.length > 0) {
        filter = `${filter === '' ? filter : `${filter} AND `}(`;

        for (let y = 0; y < config.columns[i].selected.length; y++) {
          const columnName = config.columns[i].name.split('_name');

          filter = `${filter}${y !== 0 ? ' OR ' : ''}${columnName[0]}_id=${config.columns[i].selected[y].id}`;
        }

        filter = `${filter})`;
      } else if (config.columns[i].type === Types.text && config.columns[i].text) {
        filter = `${filter === '' ? filter : `${filter} AND `}(${config.columns[i].name} LIKE '%${config.columns[i].
        text.toUpperCase()}%' COLLATE utf8_general_ci)`;
      }
    }

    return encodeURI(filter);
  }

  public removeFilter(config: TableConfig, filterColumn: Column): TableConfig {
    const columns = config.columns;

    const columnIndex = columns.findIndex((column) => {
      return column.name === filterColumn.name;
    });

    switch (columns[columnIndex].type) {
      case Types.customSelect:
        delete columns[columnIndex].customSelected;
        break;
      case Types.date:
        delete columns[columnIndex].fromValue;
        delete columns[columnIndex].toValue;
        break;
      case Types.dateTime:
        delete columns[columnIndex].fromValue;
        delete columns[columnIndex].toValue;
        break;
      case Types.multipleSelect:
        delete columns[columnIndex].selected;
        break;
      case Types.number:
        delete columns[columnIndex].fromValue;
        delete columns[columnIndex].toValue;
        break;
      case Types.select:
        delete columns[columnIndex].selected;
        break;
      case Types.text:
        delete columns[columnIndex].text;
        break;
    }

    return config;
  }
}
