import {CommonService} from './common.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class RefrigeratorsService {
  private serviceModel: string;

  constructor(private commonService: CommonService,
              private httpClient: HttpClient) {
    this.serviceModel = 'refrigerator';
  }

  public getRefrigerator(id: number): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(`${this.serviceModel}?first=true&filter=id=${id}`).subscribe((result) => {
        resolve(result);
      });
    });
  }

  public initializeRefrigerator(refrigerator: any): any {
    return {
      title: 'Στοιχεία Ψυγείου',
      values: [
        {
          name: 'Σειριακός Αριθμός',
          value: this.commonService.setValue(refrigerator.serial_number)
        },
        {
          name: 'Κωδικός Είδους',
          value: this.commonService.setValue(refrigerator.material_id)
        },
        {
          name: 'Περιγραφή Είδους',
          value: this.commonService.setValue(refrigerator.material_name)
        },
        {
          name: 'Παρτίδα',
          value: this.commonService.setValue(refrigerator.batch_name)
        },
        {
          name: 'Κωδικός Εικαστικού',
          value: this.commonService.setValue(refrigerator.eikastiko_id)
        },
        {
          name: 'Περιγραφή Εικαστικού',
          value: this.commonService.setValue(refrigerator.eikastiko_name)
        },
        {
          name: 'Καθαρό Βάρος',
          value: this.commonService.setValue(refrigerator.weight_net)
        },
        {
          name: 'Κωδικός Μοντέλου',
          value: this.commonService.setValue(refrigerator.model_id)
        },
        {
          name: 'Περιγραφή Μοντέλου',
          value: this.commonService.setValue(refrigerator.model_name)
        },
        {
          name: 'Κωδικός Κατάστασης',
          value: this.commonService.setValue(refrigerator.quality_id)
        },
        {
          name: 'Περιγραφή Κατάστασης',
          value: this.commonService.setValue(refrigerator.quality_name)
        },
        {
          name: 'Κωδικός Κατασκευαστή',
          value: this.commonService.setValue(refrigerator.manufacturer_id)
        },
        {
          name: 'Περιγραφή Κατασκευαστή',
          value: this.commonService.setValue(refrigerator.manufacturer_name)
        },
        {
          name: 'Κωδικός SAP',
          value: this.commonService.setValue(refrigerator.equipment_id)
        },
        {
          phantom: true
        },
        {
          phantom: true
        }
      ]
    };
  }
}
