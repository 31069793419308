import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmActionDialogComponent} from '../../common/confirm-action-dialog/confirm-action-dialog.component';
import {ActionCompletionDialogComponent} from '../../common/action-completion-dialog/action-completion-dialog.component';
import {PurchaseOrdersService} from '../../services/purchase-orders.service';
import {CommonService} from '../../services/common.service';

@Component({
  selector: 'app-initialize-sums-dialog',
  templateUrl: './initialize-sums-dialog.component.html',
  styleUrls: ['./initialize-sums-dialog.component.scss']
})
export class InitializeSumsDialogComponent implements OnInit {
public materials: any;
  constructor(  @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
                public dialogReference: MatDialogRef<InitializeSumsDialogComponent>,
                private commonService: CommonService,
                private purchaseOrdersService: PurchaseOrdersService) {
    this.materials = this.data.materials;
    this.materials.forEach(material => {
      material.sum = 0;
    });
    this.dialogReference.updateSize('50', '50');
    this.commonService.tempDialogReference = this.dialogReference;
  }

  ngOnInit() {
  }
  public closeDialog(): void {
    try {
      this.dialogReference.close();
    } catch (e) {
      console.log(e.message);
    }
  }

  public isSummariesFormValid(): boolean {
    const disabled = false;
    // this.materials.forEach(material => {
    //   if (material.sum === 0) {
    //     disabled = true;
    //   }
    // });

    return disabled;
  }

  public async submitSums(materials: any) {
    try {
      this.dialogReference = this.commonService.tempDialogReference;
      this.dialogReference.close(materials);
      // await this.purchaseOrdersService.storeLoadingCommand('request/web', model);
      // this.dialog.open(ActionCompletionDialogComponent, {
      //   data: {
      //     buttonText: 'Πλοήγηση στα Budget Ψυγείων',
      //     redirectURL: `refrigerator-budget`
      //   }
      // });
    } catch (e) {
      console.log(e);
    }
  }

 public async confirmInitialization() {
    this.dialog.open(ConfirmActionDialogComponent, {
      data: {
        confirmFunction: this.submitSums,
        message: `Είστε σίγουροι ότι θέλετε να αρχικοποιήσετε τα συνολα για την χρονιά ${this.data.year}? Μετά την αρχικοποίηση δεν θα μπορείτε να αλλάξετε τις τιμές.`,
        title: 'Αρχικοποίηση συνόλων',
        itemToExecute: this.materials
      }
    });
  }
}
