import {Types} from '../table/table-config';
import {CommonService} from './common.service';
import {Injectable} from '@angular/core';
import {LoadingService} from './loading.service';

@Injectable({
  providedIn: 'root'
})

export class ExportCsvService {
  private static BOM = '\ufeff';
  private static DEFAULT_FIELD_SEPARATOR = ';';
  private static EOL = '\r\n';

  private csv: string;
  private data: any;
  private fileName: string;
  private headers: any[];

  constructor(private commonService: CommonService,
              private loadingService: LoadingService) {
  }

  private formatData(data: any, index: number) {
    if (data === undefined || data === '') {
      return '-';
    }

    switch (this.headers[index].type) {
      case Types.date:
        return this.commonService.formatDate(data);
      case Types.dateTime:
        return this.commonService.formatDateTime(data);
      default:
        return data;
    }
  }

  public generateCsv(data: any, fileName: string, headers: any[]): void {
    this.csv = '';

    this.data = data;

    this.fileName = fileName;

    this.headers = headers;

    this.csv += ExportCsvService.BOM;

    this.getHeaders();

    this.getBody();

    const blob = new Blob([this.csv], {'type': 'text/csv;charset=utf8;'});

    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);

    link.setAttribute('visibility', 'hidden');

    link.download = this.fileName.replace(/ /g, '_') + '.csv';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    this.loadingService.hide();
  }

  private getBody(): void {
    for (let i = 0; i < this.data.value.length; i++) {
      let row = '';

      for (let y = 0; y < this.headers.length; y++) {
        const field = this.headers[y].name;

        if (field.indexOf('/') >= 0) {
          const fields = field.split('/');

          if (fields.length === 2) {
            if (this.data.value[i][fields[0]]) {
              row += this.formatData(this.data.value[i][fields[0]][fields[1]], y) + ExportCsvService.DEFAULT_FIELD_SEPARATOR;
            } else {
              row += this.formatData(undefined, y) + ExportCsvService.DEFAULT_FIELD_SEPARATOR;
            }
          } else {
            if (this.data.value[i][fields[0]]) {
              row += this.formatData(this.data.value[i][fields[0]][fields[1]][fields[2]], y) +
                ExportCsvService.DEFAULT_FIELD_SEPARATOR;
            } else {
              row += this.formatData(undefined, y) + ExportCsvService.DEFAULT_FIELD_SEPARATOR;
            }
          }
        } else {
          row += this.formatData(this.data.value[i][field], y) + ExportCsvService.DEFAULT_FIELD_SEPARATOR;
        }
      }

      row = row.slice(0, -1);

      this.csv += row + ExportCsvService.EOL;
    }
  }

  private getHeaders(): void {
    let row = '';

    for (let i = 0; i < this.headers.length; i++) {
      row += this.headers[i].title + ExportCsvService.DEFAULT_FIELD_SEPARATOR;
    }

    row = row.slice(0, -1);

    this.csv += row + ExportCsvService.EOL;
  }
}
