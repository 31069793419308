import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {Details} from '../details/details';
import {LoadingService} from '../services/loading.service';
import {RefrigeratorsService} from '../services/refrigerators.service';

@Component({
  templateUrl: './refrigerator-details.component.html',
  styleUrls: ['./refrigerator-details.component.scss']
})

export class RefrigeratorDetailsComponent extends Details implements OnInit {
  public basicDetailsConfig: any;
  public initializePage: boolean;
  public mainDataSections: any[];
  private refrigerator: any;
  public isLoading: boolean;

  constructor(private loadingService: LoadingService,
              private refrigeratorsService: RefrigeratorsService,
              private route: ActivatedRoute) {
    super();
    this.isLoading = true;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.initializeRefrigeratorDetails(+params.get('id'));
    });
  }

  async initializeRefrigeratorDetails(id: number) {
    this.refrigerator = await this.refrigeratorsService.getRefrigerator(id);

    this.basicDetailsConfig = {
      actions: [],
      infoRow: [],
      showActions: this.showActions(),
      status_name: this.refrigerator.quality_name,
      status_value: this.refrigerator.quality_id,
      subtitle: [
        {
          title: 'Σειριακός Αριθμός',
          value: this.refrigerator.serial_number
        }
      ],
      title: 'Πληροφορίες Ψυγείου'
    };

    this.mainDataSections = [this.refrigeratorsService.initializeRefrigerator(this.refrigerator)];

    this.initializePage = true;

    this.isLoading = false;
}

protected showActions(): boolean {
  return false;
}
}
