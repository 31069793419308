import { CommonService } from '../../services/common.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-user-basic',
  templateUrl: './user-basic.component.html',
  styleUrls: ['./user-basic.component.scss']
})

export class UserBasicComponent implements OnInit {
  @Input() mode: string;
  @Input() roles: any[];
  @Input() salesGroups: any[];
  @Input() userBasicForm: FormGroup;
  @Input() users: any[];
  @Input() hasChildren: boolean;

  public filteredRoles: ReplaySubject<any[]>;
  public filteredSalesGroups: ReplaySubject<any[]>;
  public filteredUsers: ReplaySubject<any[]>;
  public onDestroy: Subject<void>;
  public rolesFilterControl: FormControl;
  public salesGroupsFilterControl: FormControl;
  public usersFilterControl: FormControl;

  constructor(public commonService: CommonService,
    public usersService: UsersService) {
    this.filteredRoles = new ReplaySubject<any[]>(1);

    this.filteredSalesGroups = new ReplaySubject<any[]>(1);

    this.filteredUsers = new ReplaySubject<any[]>(1);

    this.onDestroy = new Subject<void>();

    this.rolesFilterControl = new FormControl();

    this.salesGroupsFilterControl = new FormControl();

    this.usersFilterControl = new FormControl();
  }

  ngOnInit(): void {
    this.filteredRoles.next(this.roles.slice());

    this.commonService.initializeControls(this.filteredRoles, this.rolesFilterControl, this.onDestroy, this.roles);

    this.filteredSalesGroups.next(this.salesGroups.slice());

    this.commonService.initializeControls(this.filteredSalesGroups, this.salesGroupsFilterControl, this.onDestroy,
      this.salesGroups);

    this.filteredUsers.next(this.users.slice());

    this.commonService.initializeControls(this.filteredUsers, this.usersFilterControl, this.onDestroy, this.users);
  }

  public setSalesGroupsMargin(): number {
    if (!this.usersService.showRepresentative(this.userBasicForm) && !this.showStatus()) {
      return 34;
    } else {
      return 0;
    }
  }

  public setStatusMargin(): number {
    if (!this.usersService.showSalesGroup(this.userBasicForm)) {
      return 34;
    } else {
      return 0;
    }
  }

  public isRoleDisabled(): boolean {
    if (this.hasChildren) {
      return true;
    } else {
      if (this.userBasicForm.value.role.alternate_name === 'salesInspector' || this.userBasicForm.value.role.alternate_name === 'regionalDirector') {
        return true;
      } else {
        return false;
      }
    }
  }

  public showStatus(): boolean {
    return this.mode === 'edit';
  }
}
