import { AuthService } from './auth.service';
import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SalePointsGuardService implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) {
  }

  canActivate() {
    return this.authService.getUser().then((user) => {
      if (user.role_alternate_name === 'basicUser' ||
        user.role_alternate_name === 'transportManager' ||
        user.role_alternate_name === 'technicalManager' ||
        user.role_alternate_name === 'driver' ||
        user.role_alternate_name === 'ownNetworkDistributor' ||
        user.role_alternate_name === 'ownNetworkRepresentative' ||
        user.role_alternate_name === 'districtRepresentative' ||
        user.role_alternate_name === 'salesInspector' ||
        user.role_alternate_name === 'technician' ||
        user.role_alternate_name === 'regionalDirector') {
        return true;
      }

      this.router.navigate(['access-denied']);

      return false;
    });
  }
}
