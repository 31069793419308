import {CommonService} from '../../services/common.service';
import {Component, Input} from '@angular/core';
import {LoadingService} from '../../services/loading.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import * as moment from 'moment';

@Component({
  selector: 'app-event-logs',
  templateUrl: './event-logs.component.html',
  styleUrls: ['./event-logs.component.scss']
})

export class EventLogsComponent {
  @Input() eventLogs: any[];

  constructor(public commonService: CommonService,
              private loadingService: LoadingService) {
  }

  public showDivider(index: number): boolean {
    return index !== this.eventLogs.length - 1;
  }

  public updateEventLogStatus(event: MatCheckboxChange, eventLog: any): void {
    this.loadingService.show();

    const draftEventLog = {
      date_done: moment().format('YYYY-MM-DD HH:mm:ss'),
      id: eventLog.id,
      is_done: event.checked
    };

    this.commonService.updateObject('event_log', draftEventLog).then(() => {
      this.loadingService.hide();
    });
  }
}
