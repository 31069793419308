import {ActivatedRoute, Router} from '@angular/router';
import {Column, TableConfig, Types} from './table-config';
import {AuthService} from '../services/auth.service';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {CommonService} from '../services/common.service';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ExportCsvService} from '../services/export-csv.service';
import {FilterTableDialogComponent} from './filter-table-dialog/filter-table-dialog.component';
import {HttpClient} from '@angular/common/http';
import {InventoryRefrigeratorsDialogComponent} from '../inventory-details/inventory-refrigerators-dialog/inventory-refrigerators-dialog.component';
import {LoadingService} from '../services/loading.service';
import {MatDialog, MatDialogRef, MatPaginator, MatSort, SortDirection} from '@angular/material';
import {merge, of as observableOf, Observable} from 'rxjs';
import {TableFiltersService} from '../services/table-filters.service';
import * as moment from 'moment';
import {environment} from '../../environments/environment';
import RefrigeratorsConfig from './configs/inventory-refrigerators.json';
import {PurchaseOrdersService} from '../services/purchase-orders.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit {
  @Input() inputConfig: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public config: TableConfig;
  public data: any[];
  public displayedColumns: string[];
  public shortDisplayedColumns: string[] = [];
  private filter: string;
  public filterChips: any[];
  private filterTableDialogFilter: string;
  private filterTableDialogRef: MatDialogRef<FilterTableDialogComponent>;
  public filterValues: any[];
  public isLoading: boolean;
  private initialConfig: any;
  private initialFilter: string;
  private initializeFilters: boolean;
  public pageSize: number;
  public orderDetailsId: number;
  public resultsLength: number;
  private searchFilter: string;
  public searchValue: string;
  public showInsertButton: boolean;
  public imageFilesSource: any;
  public purchaseDocument: string;
  public orderDetails: any;
  public orderDetailsSerials: any;
  public bColor: string;

  constructor(private authService: AuthService,
              private commonService: CommonService,
              private dialog: MatDialog,
              private exportCSVService: ExportCsvService,
              private httpClient: HttpClient,
              private loadingService: LoadingService,
              private purchaseOrdersService: PurchaseOrdersService,
              public route: ActivatedRoute,
              private router: Router,
              private tableFiltersService: TableFiltersService) {
    this.bColor = 'primary';
    this.isLoading = true;
    this.data = [];

    this.displayedColumns = [];

    this.filterTableDialogFilter = '';

    this.imageFilesSource = [];
    this.initializeFilters = false;

    this.pageSize = 25;

    this.resultsLength = 0;

    this.searchFilter = '';

    this.searchValue = '';

  }

  ngOnInit(): void {
    // this.loadingService.show();
    this.purchaseDocument = localStorage.getItem('purchaseDocument');
    this.orderDetails = JSON.parse(localStorage.getItem('orderDetails'));
    this.orderDetailsSerials = JSON.parse(localStorage.getItem('orderDetailsSerials'));
    this.route.params.subscribe(params => {
      this.orderDetailsId = params.id;
    });
    this.route.data.subscribe((data) => {
      if (data.config) {
        this.config = data.config;
      } else {
        this.config = this.inputConfig;
      }
    });

    if (this.config.insertButtonText) {
      const roles = this.config.insertRole.split(',');

      for (let i = 0; i < roles.length; i++) {
        if (this.authService.isUserBelongsTo(roles[i])) {
          this.showInsertButton = true;

          break;
        }
      }
    }

    for (let i = 0; i < this.config.columns.length; i++) {
      this.displayedColumns.push(this.config.columns[i].name);
      if (i < 3) {
        this.shortDisplayedColumns.push(this.config.columns[i].name);
      }
    }

    if (this.config.filter) {
      this.initialFilter = this.config.filter;
    } else {
      this.initialFilter = '';
    }

    this.paginator = this.commonService.setPaginatorTexts(this.paginator);

    if (this.config.orderColumn) {
      this.sort.active = this.config.orderColumn;

      this.sort.direction = this.config.orderDirection as SortDirection;
    }

    this.sort.sortChange.subscribe(() => {
      this.loadingService.show();

      this.paginator.pageIndex = 0;
    });

    this.paginator.page.subscribe(() => {
      this.loadingService.show();
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.httpClient.get(this.initializeCallURL());
        }),
        map((data: any) => {
          this.resultsLength = data.total;
          if (this.config.model === 'loading_command/web/details/serial_numbers') {
            for (let i = 0; i < data.value.length; i++) {
              const d = data.value[i];
              d.stage_name = d.stage_id === 1 ? 'Προς Εκτέλεση' : d.stage_id === 2 ? 'Προς Αποστολή' : 'Απεσταλμένο';
            }
          }
          // this.loadingService.hide();
          this.isLoading = false;
          return data.value;
        }),
        catchError((e) => {
          console.log(e);
          this.loadingService.hide();

          return observableOf([]);
        })
      ).subscribe((data) =>
      this.data = data);
  }

  public allowExport(): boolean {
    return this.config.allowExport;
  }

  private applyFilter() {
    this.paginator.pageIndex = 0;
    this.isLoading = true;
    // this.loadingService.show();
    return this.httpClient.get(this.initializeCallURL()).subscribe((data: any) => {
      this.resultsLength = data.total;
      this.data = data.value;
      this.isLoading = false;
      // this.loadingService.hide();
    });
  }

  public changePageSize(): void {
    this.pageSize = this.paginator.pageSize;
  }

  public getRecord(row: any): void {
    if (this.config.showRefrigerators) {
      this.loadingService.show();

      const refrigeratorsConfig = RefrigeratorsConfig;

      refrigeratorsConfig.filter = `inventory_sale_point_id = ${row.id}`;

      this.dialog.open(InventoryRefrigeratorsDialogComponent, {
        data: {
          refrigeratorsConfig
        }
      });
    } else if (this.config.model === 'event_log') {
      this.router.navigate([row.model, row.model_id]);
    } else if (this.config.navigatePath) {
      if (this.config.model === 'purchase_orders') {
        localStorage.setItem('purchaseOrder', JSON.stringify(row));
      }
      if (this.config.model === 'loading_command/web' || this.config.model === 'unloading_command/web') {
        const details = {
          order_id: row.id,
          order_id1: row.order_id,
          order_type_id: row.order_type_id,
          desired_delivery_date: row.desired_delivery_date,
          sale_point_id: row.sale_point_id,
          user_id: row.user_id,
          recipient: row.sale_point_name,
          address: row.sale_point_address,
          status: row.status_id ? row.status_id : null
        };
        localStorage.setItem('orderDetails', JSON.stringify(details));
      }
      if (this.config.model === 'loading_command/web/details' || this.config.model === 'unloading_command/web/details') {
        const details = {
          order_id: this.orderDetails.order_id,
          material_id: row.material_id
        };
        localStorage.setItem('orderDetailsSerials', JSON.stringify(details));
      }
      if (this.config.model === 'request/web') {
        const details = {
          requestId: row.id,
          status_id: row.status_id,
          type_id: row.type_id,
          sale_point_id: row.sale_point_id
        };
        localStorage.setItem('requestDetails', JSON.stringify(details));
      }
      if (this.config.model === 'return_command/web') {
        const details = {
          requestId: row.id,
          status_id: row.status_id,
          city: row.recipient_sale_point_city,
          recipient: row.recipient_sale_point_customer_name,
          typeId: row.type_id
        };
        localStorage.setItem('returnDetails', JSON.stringify(details));
      }
      this.router.navigate([this.config.navigatePath, row[this.config.navigateProperty]]);
    } else if (this.config.model === 'inventory_refrigerator') {
      this.loadingService.show();

      this.imageFilesSource = [];

      const filter = `inventory_refrigerator_id=${row.id}`;
      this.commonService.getListWithParameters('inventory_refrigerator_file', filter).then((files: any) => {

        for (let i = 0; i < files.length; i++) {
          const imageFile = `${environment.apiUrl}files?name=${files[i].file_name}`;
          this.imageFilesSource.push(imageFile);
        }
        this.loadingService.hide();
      });
    }
  }

  public exportCSV(): void {
    this.loadingService.show();

    this.httpClient.get(this.initializeCallURL('export')).subscribe((data: any) => {
      this.exportCSVService.generateCsv(data, this.config.title, this.config.columns);
    });
  }

  public formatValue(value: any, column: any): string {
    switch (column.type) {
      case Types.date:
        return value ? this.commonService.formatDate(value) : '-';
      case Types.dateTime:
        return value ? this.commonService.formatDateTime(value) : '-';
      case Types.number:
        return value !== undefined ? value : '-';
      default:
        return value ? value : '-';
    }
  }

  private initializeCallURL(mode?: string): string {
    let query = '';

    query = `${this.config.model}?count=true`;

    if (mode !== 'export') {
      query += `&limit=${this.pageSize}&skip=${this.pageSize * this.paginator.pageIndex}`;
    }

    this.filter = this.initialFilter;

    if (this.searchFilter !== '') {
      this.filter = `${this.filter === '' ? '' : `${this.filter} AND `}(${this.searchFilter})`;
    }

    if (this.filterTableDialogFilter !== '') {
      this.filter = `${this.filter === '' ? '' : `${this.filter} AND `}(${this.filterTableDialogFilter})`;
    }

    if (this.filter) {
      query += `&filter=${this.filter}`;
    }

    if (this.sort.active) {
      query += `&order=${this.sort.active} ${this.sort.direction}`;
    }

    if (this.config.model === 'request/web') {
      query += '&order=id asc';
    }

    return query;
  }

  public initializeFilterTableDialog(): void {
    if (this.initializeFilters) {
      this.openFilterTableDialog();
    } else {
      this.loadingService.show();

      let promises: Array<Promise<any>>;

      promises = [];

      for (let i = 0; i < this.config.columns.length; i++) {
        if (this.config.columns[i].type === Types.multipleSelect || this.config.columns[i].type === Types.select) {
          const promise = this.commonService.getList(this.config.columns[i].select.model).then((result: any) => {
            this.config.columns[i].select.options = result;
          });

          promises.push(promise);
        }
      }

      Promise.all(promises).then(() => {
        this.initialConfig = JSON.parse(JSON.stringify(this.config));

        this.initializeFilters = true;

        this.openFilterTableDialog();

        this.loadingService.hide();
      });
    }
  }

  public navigateToForm() {
    this.router.navigate([this.config.insertLink]);
  }

  public openFilterTableDialog(): void {
    this.filterTableDialogRef = this.dialog.open(FilterTableDialogComponent, {
      data: {
        config: this.config,
        initialConfig: this.initialConfig
      },
      position: {
        right: '0'
      }
    });

    this.filterTableDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.config = result.config;

        this.filterTableDialogFilter = result.filter;

        this.searchFilter = '';

        this.searchValue = '';

        this.setFilterValues();

        this.applyFilter();
      }
    });
  }

  public removeAllFilters(): void {
    this.loadingService.show();

    this.config = JSON.parse(JSON.stringify(this.initialConfig));

    this.filterTableDialogFilter = this.tableFiltersService.getFilter(this.config);

    this.setFilterValues();

    this.applyFilter();
  }

  public removeFilterChip(filterChip: Column): void {
    this.loadingService.show();

    this.config = this.tableFiltersService.removeFilter(this.config, filterChip);

    this.filterTableDialogFilter = this.tableFiltersService.getFilter(this.config);

    this.setFilterValues();

    this.applyFilter();
  }

  private setFilterValues(): void {
    this.filterChips = [];

    this.filterValues = [];

    for (let i = 0; i < this.config.columns.length; i++) {
      if (this.config.columns[i].type === Types.customSelect && this.config.columns[i].customSelected) {
        this.filterChips.push(this.config.columns[i]);

        let value: string;

        value = '';

        for (let y = 0; y < this.config.columns[i].customSelected.length; y++) {
          value = `${value === '' ? value : `${value} ή `}${this.config.columns[i].customSelected[y].name}`;
        }

        this.filterValues.push({
          title: this.config.columns[i].title,
          value
        });
      } else if ((this.config.columns[i].type === Types.date || this.config.columns[i].type === Types.dateTime) &&
        (this.config.columns[i].fromValue || this.config.columns[i].toValue)) {
        this.filterChips.push(this.config.columns[i]);

        this.filterValues.push({
          title: this.config.columns[i].title,
          value: `${this.config.columns[i].fromValue ? this.commonService.formatDate(this.config.columns[i].fromValue) :
            this.commonService.formatDate(moment())} - ${this.config.columns[i].toValue ?
            this.commonService.formatDate(this.config.columns[i].toValue) : this.commonService.formatDate(moment())}`
        });
      } else if ((this.config.columns[i].type === Types.number) &&
        (this.config.columns[i].fromValue || this.config.columns[i].toValue)) {
        this.filterChips.push(this.config.columns[i]);

        this.filterValues.push({
          title: this.config.columns[i].title,
          value: `${this.config.columns[i].fromValue ? this.config.columns[i].fromValue : 0} - ${this.config.columns[i].toValue ? this.config.columns[i].toValue : 0}`
        });
      } else if ((this.config.columns[i].type === Types.select ||
        this.config.columns[i].type === Types.multipleSelect) && this.config.columns[i].selected &&
        this.config.columns[i].selected.length > 0) {
        this.filterChips.push(this.config.columns[i]);

        let value: string;

        value = '';

        for (let y = 0; y < this.config.columns[i].selected.length; y++) {
          value = `${value === '' ? value : `${value} ή `}${this.config.columns[i].selected[y].name}`;
        }

        this.filterValues.push({
          title: this.config.columns[i].title,
          value
        });
      } else if (this.config.columns[i].type === Types.text && this.config.columns[i].text) {
        this.filterChips.push(this.config.columns[i]);

        this.filterValues.push({
          title: this.config.columns[i].title,
          value: this.config.columns[i].text.toUpperCase()
        });
      }
    }
  }

  public setIcon(value: any, column: any): string {
    return value[column.name.replace('_name', '_value')] === 0 ? 'cancel' : 'check_circle';
  }

  public setIconColor(value: any, column: any): string {
    return value[column.name.replace('_name', '_value')] === 0 ? '#dd0330' : '#4caf50';
  }

  public setSearchFilter(): void {
    this.loadingService.show();

    this.searchFilter = '';

    const searchFields = this.config.searchFields.split(',');

    for (let i = 0; i < searchFields.length; i++) {
      this.searchFilter += `${searchFields[i]} LIKE '%${this.searchValue}%' COLLATE utf8_general_ci OR `;
    }

    this.searchFilter = encodeURI(this.searchFilter.slice(0, -4));

    this.applyFilter();
  }

  public setTableHeight(): number {
    return this.config.nested ? window.innerHeight - 403 : window.innerHeight - 272;
  }

  public setValue(value: any, column: any): string {
    if (value[column.name] !== undefined) {
      return this.formatValue(value[column.name], column);
    } else {
      return '-';
    }
  }

  public showFilterChips(): boolean {
    return this.filterChips && this.filterChips.length > 0;
  }

  public showIcon(column: any): boolean {
    return this.config.model === 'event_log' && (column.name === 'is_displayed_name' || column.name === 'is_done_name');
  }
}
