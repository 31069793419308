import { AuthService } from '../services/auth.service';
import { CommonService } from '../services/common.service';
import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '../services/loading.service';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {
  public errorLoginNotFound: boolean;
  public errorLoginUnauthorized: boolean;
  public userForm: FormGroup;

  constructor(private authService: AuthService,
    public commonService: CommonService,
    private cookieService: CookieService,
    private loadingService: LoadingService,
    private loginService: LoginService,
    private router: Router) {
    this.errorLoginNotFound = false;
    this.errorLoginUnauthorized = false;

    this.userForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });

    this.loadingService.hide();
  }

  public isFormValid(): boolean {
    return !this.userForm.valid;
  }

  public keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.loginUser();
    }
  }


  public loginUser(): void {
    this.loadingService.show();

    this.errorLoginUnauthorized = false;
    this.errorLoginNotFound = false;

    this.loginService.login(this.userForm.value).then((response) => {
      if (response.status === 200) {
        this.cookieService.set('Token', response.body);

        this.router.navigate(['/home']);
      }
      if (response.status === 404) {
        this.loadingService.hide();

        this.errorLoginNotFound = true;
      }

      if (response.status === 401) {
        this.loadingService.hide();

        this.errorLoginUnauthorized = true;
      }
    }).catch((status) => {
      this.loadingService.hide();

      this.errorLoginNotFound = true;
    });
  }
}
