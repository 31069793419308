import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrdersService {
  private model: string;

  constructor(private httpClient: HttpClient) {
    this.model = 'purchase_orders';
  }

  public getList(model: string, filter?: string, options?: boolean): Promise<any> {
    let request;
    if (options && filter) {
      request = `${model}?count=true&limit=1000&skip=0&filter=${filter}`;
    } else if (options && !filter) {
      request = `${model}?count=true&limit=1000`;
    } else if (filter && !options) {
      request = `${model}?filter=${filter}`;
    } else {
      request = `${model}`;
    }

    if (model === 'sale_point') {
      request += '&order=customer_name asc';
    }

    return new Promise((resolve) => {
      this.httpClient.get(request).subscribe((result) => {
        resolve(result);
      });
    });
  }

  public async updateReceiptCommand(model: string, id: any): Promise<any> {
    try {
      return await this.httpClient.put(model, id).toPromise();
    } catch (e) {
      if (e) {
        return Promise.reject({error: e});
      }
    }
  }

  public async storeLoadingCommand(model: string, command: any): Promise<any> {
    try {
      return await this.httpClient.post(model, command).toPromise();
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
}
