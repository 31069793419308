import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import {AuthService} from './auth.service';
import {MatDialogRef} from '@angular/material/dialog';
import {InitializeSumsDialogComponent} from '../refrigerator-budget/initialize-sums-dialog/initialize-sums-dialog.component';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  public tempDialogReference: any;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  private filterValues(filterValues: ReplaySubject<any[]>, formControl: FormControl, values: any[]): void {
    if (!values) {
      return;
    }

    let search = formControl.value;

    if (!search) {
      filterValues.next(values.slice());

      return;
    } else {
      search = search.toLowerCase();
    }

    filterValues.next(values.filter(value => value.name.toLowerCase().indexOf(search) > -1));
  }

  public findElement(element: any, elements: any[]): any {
    const elementIndex = elements.findIndex((data) => {
      return element === data.id;
    });

    return elements[elementIndex];
  }

  public formatDate(value: any): string {
    return moment(value).format('DD/MM/YYYY');
  }

  public formatDateTime(value: any): string {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
  }

  public getList(model: string) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${model}?order=name asc`).subscribe((result) => {
        resolve(result);
      }, error => reject({message: 'No Data'}));
    });
  }

  public getListWithParameters(model: string, filter: string) {
    return new Promise((resolve) => {
      this.httpClient.get(`${model}?filter=${filter}`).subscribe((result) => {
        resolve(result);
      }, error => {
        console.log(error);
      });
    });
  }

  public initializeControls(filterValues: ReplaySubject<any[]>, formControl: FormControl, onDestroy: Subject<void>, values: any[]): void {
    formControl.valueChanges.pipe(takeUntil(onDestroy)).subscribe(() => {
      this.filterValues(filterValues, formControl, values);
    });
  }

  public insertObject(model: string, object: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(`${model}`, object).subscribe((result) => {
        resolve(result);
      }, error => reject({error}));
    });
  }

  public isFormFieldValid(form: FormGroup, formField: string): boolean {
    return form.get(formField).touched && form.get(formField).invalid;
  }

  public setErrorMessage(form: FormGroup, formField: string, type?: string): string {
    switch (type) {
      case 'email':
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        if (form.get(formField).errors.email) {
          return 'Λάθος τύπος email.';
        }

        break;
      case 'postalCode':
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        if (form.get(formField).errors.minlength) {
          return 'Συμπληρώστε 5 ψηφία.';
        }

        break;
      case 'telephone':
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        if (form.get(formField).errors.minlength) {
          return 'Συμπληρώστε 10 ψηφία.';
        }

        break;
      case 'temporaryPassword':
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        if (form.get(formField).errors.minlength) {
          return 'Συμπληρώστε 6 ψηφία.';
        }

        break;
      case 'vatNumber':
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        if (form.get(formField).errors.minlength) {
          return 'Συμπληρώστε 9 ψηφία.';
        }

        if (form.get(formField).errors.invalidVatNumber) {
          return 'Λάθος μορφή Α.Φ.Μ..';
        }

        if (form.get(formField).errors.uniqueVatNumber) {
          return 'Υπάρχει εταιρεία με το ίδιο Α.Φ.Μ..';
        }

        break;
      default:
        if (form.get(formField).errors.required) {
          return 'Υποχρεωτικό πεδίο.';
        }

        break;
    }
  }

  public setPaginatorTexts(paginator: MatPaginator): MatPaginator {
    paginator._intl.itemsPerPageLabel = 'Εγγραφές Ανά Σελίδα';
    paginator._intl.firstPageLabel = 'Πρώτη Σελίδα';
    paginator._intl.lastPageLabel = 'Τελευταία Σελίδα';
    paginator._intl.nextPageLabel = 'Επόμενη Σελίδα';
    paginator._intl.previousPageLabel = 'Προηγούμενη Σελίδα';

    const greekRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 από ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} από ${length}`;
    };

    paginator._intl.getRangeLabel = greekRangeLabel;

    return paginator;
  }

  public setValue(object: any): string {
    return object ? object : '-';
  }

  public showActions(user: any): boolean {
    return (this.authService.isUserBelongsTo('basicUser') ||
      this.authService.isUserBelongsTo('transportManager') || this.authService.isUserBelongsTo('technicalManager')) &&
      (user.role_alternate_name !== 'regionalDirector' && user.role_alternate_name !== 'salesInspector');
  }

  public showTable(table: any[]): boolean {
    return table && table.length > 0;
  }

  public updateObject(model: string, object: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.put(`${model}`, object).subscribe((result) => {
        resolve(result);
      });
    });
  }
}
