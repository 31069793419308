import { Component, OnInit } from '@angular/core';
import {LoadingService} from '../services/loading.service';
import {MatTabGroup} from '@angular/material';
import ReceiptOrdersConfig from '../table/configs/receipt_orders.json';

@Component({
  selector: 'app-receipt-orders',
  templateUrl: './receipt-orders.component.html',
  styleUrls: ['./receipt-orders.component.scss']
})
export class ReceiptOrdersComponent implements OnInit {
  public config: any;
  public OrdersToReceive: any;
  public OrdersReceived: any;

  public show0: boolean;
  public show1: boolean;

 private initializePage: boolean;
  constructor(private loadingService: LoadingService) {
    this.config = {
      title: 'Εντολές Παραλαβής',
      model: 'receipt_orders',
      nested: true
    };

    this.OrdersToReceive = (JSON.parse(JSON.stringify(ReceiptOrdersConfig)));
    this.OrdersToReceive.filter = 'command_type = 1 AND stage_id = 4';
    this.OrdersReceived = (JSON.parse(JSON.stringify(ReceiptOrdersConfig)));
    this.OrdersReceived.filter = 'command_type = 1 AND stage_id = 5';

    this.show0 = true;
    this.show1 = false;
  }
  ngOnInit() {
    this.initializePage = true;
    this.loadingService.hide();
  }


  public changeTab(tabs: MatTabGroup): void {
    if (tabs.selectedIndex === 0) {
      this.show0 = true;
      this.show1 = false;
    }
    if (tabs.selectedIndex === 1) {
      this.show0 = false;
      this.show1 = true;
    }
  }
}
