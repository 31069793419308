import {AuthService} from '../services/auth.service';
import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})

export class LogoutDialogComponent implements OnInit {
  constructor(private authService: AuthService,
              private dialogRef: MatDialogRef<LogoutDialogComponent>) {
  }

  ngOnInit() {
    this.dialogRef.updateSize('600px');
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public logout(): void {
    this.dialogRef.close();

    this.authService.logout();
  }
}
