import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {SalePointsService} from '../../services/sale-points.service';

@Component({
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss']
})

export class DetailsDialogComponent {
  public mainDataSections: any[];
  private object: any;
  public title: string;
  private type: string;

  constructor(private dialogRef: MatDialogRef<DetailsDialogComponent>,
              private salePointsService: SalePointsService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.object = this.data.object;

    this.type = this.data.type;

    this.mainDataSections = [];

    if (this.type === 'salePoint') {
      this.title = 'Προβολή Σημείου Πώλησης';

      this.mainDataSections = this.salePointsService.initializeSalePoint(this.object);
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
