import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})

export class BasicInfoComponent {
  @Input() mainDataSections: any[];

  constructor() {
  }
}
