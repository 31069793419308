import {CommonService} from '../../services/common.service';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LoadingService} from '../../services/loading.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ReplaySubject, Subject} from 'rxjs';
import {TableConfig, Types} from '../table-config';
import {TableFiltersService} from '../../services/table-filters.service';

@Component({
  templateUrl: './filter-table-dialog.component.html',
  styleUrls: ['./filter-table-dialog.component.scss']
})

export class FilterTableDialogComponent implements OnInit, OnDestroy {
  public config: TableConfig;
  private initialConfig: TableConfig;
  public onDestroy: Subject<void>;

  constructor(private commonService: CommonService,
              private dialogRef: MatDialogRef<FilterTableDialogComponent>,
              private loadingService: LoadingService,
              private tableFiltersService: TableFiltersService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.config = this.data.config;

    this.initialConfig = this.data.initialConfig;

    this.onDestroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('500px', `${window.innerHeight}px`);

    this.initializeConfig();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();

    this.onDestroy.complete();
  }

  public applyFilter(): void {
    this.loadingService.show();

    this.dialogRef.close({
      config: this.config,
      filter: this.tableFiltersService.getFilter(this.config)
    });
  }

  public clearFilter(): void {
    this.config = JSON.parse(JSON.stringify(this.initialConfig));

    this.initializeConfig();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public initializeConfig(): void {
    for (let i = 0; i < this.config.columns.length; i++) {
      if (this.showElement(this.config.columns[i], 'customSelect')) {
        this.config.columns[i].selectFilterControl = new FormControl();

        this.config.columns[i].filteredSelect = new ReplaySubject<any[]>(1);

        this.config.columns[i].filteredSelect.next(this.config.columns[i].customSelect.slice());

        this.commonService.initializeControls(this.config.columns[i].filteredSelect, this.config.columns[i].selectFilterControl,
          this.onDestroy, this.config.columns[i].customSelect);
      }

      if (this.showElement(this.config.columns[i], 'multipleSelect') || this.showElement(this.config.columns[i], 'select')) {
        this.config.columns[i].selectFilterControl = new FormControl();

        this.config.columns[i].filteredSelect = new ReplaySubject<any[]>(1);

        this.config.columns[i].filteredSelect.next(this.config.columns[i].select.options.slice());

        this.commonService.initializeControls(this.config.columns[i].filteredSelect, this.config.columns[i].selectFilterControl,
          this.onDestroy, this.config.columns[i].select.options);
      }
    }
  }

  public showElement(column: any, type: string): boolean {
    return column.type === Types[type];
  }
}
