import {Component} from '@angular/core';
import {LoadingService} from '../services/loading.service';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent {
  constructor(private loadingService: LoadingService) {
    this.loadingService.hide();
  }
}
