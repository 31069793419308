import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {LoadingService} from '../services/loading.service';

@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})

export class ErrorComponent implements OnInit {
  public icon: string;
  public height: number;
  public message: string;

  constructor(private loadingService: LoadingService,
              private route: ActivatedRoute) {
    this.loadingService.hide();

    this.height = window.innerHeight - 96;
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      if (data.mode === 'access-denied') {
        this.icon = 'report_problem';

        this.message = 'Δεν έχετε δικαίωμα πρόσβασης σ\' αυτή τη σελίδα';
      } else {
        this.icon = 'report';

        this.message = 'Δεν υπάρχει η σελίδα που αναζητήσατε.';
      }
    });
  }
}
