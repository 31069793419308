import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import OrderDetailsConfig from '../table/configs/order-request-details.json';
import {ActionCompletionDialogComponent} from '../common/action-completion-dialog/action-completion-dialog.component';
import {PurchaseOrdersService} from '../services/purchase-orders.service';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ConfirmActionDialogComponent} from '../common/confirm-action-dialog/confirm-action-dialog.component';
import {ErrorDialogComponent} from '../common/error-dialog/error-dialog.component';
import {FunctionCall} from '@angular/compiler';
import {AuthService} from '../services/auth.service';
import {UsersService} from '../services/users.service';
import {AssignAndDetailModalComponent} from '../common/assign-and-detail-modal/assign-and-detail-modal.component';
import {LoadingService} from '../services/loading.service';
import {AssignRequestComponent} from '../return-refrigerator-details/assign-request/assign-request.component';
import {CommonService} from '../services/common.service';
import {CreateLoadingRequestComponent} from '../common/create-loading-request/create-loading-request.component';
import {CreateUnloadingRequestComponent} from '../common/create-unloading-request/create-unloading-request.component';

@Component({
  selector: 'app-request-order-details',
  templateUrl: './request-order-details.component.html',
  styleUrls: ['./request-order-details.component.scss']
})
export class RequestOrderDetailsComponent implements OnInit {
  public orderDetailsConfig: any;
  public requestDetails: any;
  public basicDetailsConfig: any;
  public showBasicInfo: boolean;
  private user: any;

  constructor(public route: ActivatedRoute, public purchaseOrdersService: PurchaseOrdersService,
              private dialog: MatDialog, private authService: AuthService,
              public loadingService: LoadingService, private commonService: CommonService) {
    this.requestDetails = JSON.parse(localStorage.getItem('requestDetails'));
    this.showBasicInfo = false;
    this.initUser();
  }


  private async initUser() {
    this.user = await this.authService.getUser();
    this.initBasicInfo();
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.orderDetailsConfig = JSON.parse(JSON.stringify(OrderDetailsConfig));
      this.orderDetailsConfig.filter = `request_id=${params.id}`;

      if (this.requestDetails.type_id === 6) {
        const columns = this.orderDetailsConfig.columns;
        columns.splice(4, 0, {
          name: 'passed_new',
          title: 'Ποσότητα νέων προς έγκριση ψυγείων',
          type: 'text'
        });

        columns.splice(5, 0, {
          name: 'passed_used',
          title: 'Ποσότητα μεταχειρισμένων προς έγκριση ψυγείων',
          type: 'text'
        });
        columns.push({
          name: 'sales_inspector_action_description',
          title: 'Ενέργεια Επιθεωρητή',
          type: 'text'
        });
      }
    });
  }

  private async initBasicInfo() {
    this.showBasicInfo = true,
    this.basicDetailsConfig = {
      showActions: await this.commonService.showActions(this.user),
      subtitle: [
        {
          title: 'Αριθμός αίτησης',
          value: this.requestDetails.requestId
        }
      ],
      title: 'Πληροφορίες Αίτησης'
    };

    this.initializeActions();
  }

  private initializeActions(): void {
    const actions = [];
    const action = {
      function: () => {
        this.initializeCreateLoadingRequest(2);
      },
      showAction: true,
      title: 'Δημιουργία εντολής φόρτωσης',
      icon: 'cloud_upload',
      color: 'green'
    };
    actions.push(action);
    const action2 = {
      function: () => {
        this.initializeCreateUnloadingOrderDialog(2);
      },
      showAction: true,
      title: 'Δημιουργία Εντολής Παραλαβής',
      icon: 'cloud_download',
      color: '#266ccd'
    };

    actions.push(action2);
    if (actions.length > 0) {
      this.basicDetailsConfig.actions = actions;
      this.basicDetailsConfig.showActions = true;
    } else {
      this.basicDetailsConfig.showActions = false;
    }
  }

  public async confirmCancellation() {
    this.dialog.open(ConfirmActionDialogComponent, {
      data: {
        confirmFunction: this.cancelRequest,
        message: `Είστε σίγουροι ότι θέλετε να ακυρώσετε την αίτηση με αριθμό ${this.requestDetails.requestId}?`,
        title: 'Ακύρωση Αίτησης',
        itemToExecute: {id: this.requestDetails.requestId}
      }
    });
  }

  public async cancelRequest(object: any) {
    console.log('cancelling');
    try {
      await this.purchaseOrdersService.updateReceiptCommand('request/web', object);
      this.dialog.open(ActionCompletionDialogComponent, {
        data: {
          buttonText: 'Πλοήγηση στις αιτήσεις',
          redirectURL: `purchase_order_requests`
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  private async initializeCreateLoadingRequest(type: number) {
    this.loadingService.show();
    const users = await this.commonService.getListWithParameters('user', 'role_id=2 OR role_id = 13 OR role_id = 14');
    const details = await this.commonService.getListWithParameters('request/web/details', `request_id=${this.requestDetails.requestId}`);
    this.loadingService.hide();
    this.dialog.open(CreateLoadingRequestComponent, {
      data: {
        users,
        details,
        order_id:  this.requestDetails.requestId,
        type,
        purchaseDocument: null
      }
    });
  }


  private async initializeCreateUnloadingOrderDialog(type: number) {
    this.loadingService.show();
    const users = await this.commonService.getListWithParameters('user', 'role_id=2 OR role_id=13 OR role_id=14');
    const details = await this.commonService.getListWithParameters('request/web/details', `request_id=${this.requestDetails.requestId}`);
    const roles = await this.commonService.getListWithParameters('role', 'id=6 OR id=13 OR id=14');
    this.loadingService.hide();
    this.dialog.open(CreateUnloadingRequestComponent, {
      data: {
        orderType: 1,
        roles,
        users,
        details,
        type,
        order_id: this.requestDetails.requestId,
        purchaseDocument: null
      }
    });
  }
}
