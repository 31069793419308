import {ActionCompletionDialogComponent} from '../../common/action-completion-dialog/action-completion-dialog.component';
import {CommonService} from '../../services/common.service';
import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {LoadingService} from '../../services/loading.service';
import {MatDialog} from '@angular/material/dialog';
import {UsersService} from '../../services/users.service';

@Component({
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})

export class UserFormComponent implements OnInit {
  public initializePage: boolean;
  public roles: any[];
  public salesGroups: any[];
  private user: any;
  public userBasicForm: FormGroup;
  public users: any[];

  constructor(private commonService: CommonService,
              private dialog: MatDialog,
              private loadingService: LoadingService,
              public usersService: UsersService) {
    this.userBasicForm = this.usersService.initializeUserBasicForm();
  }

  ngOnInit(): void {
    Promise.all(this.usersService.initializeUserForm()).then((result) => {
      this.roles = this.usersService.initializeRoles(result[0]);

      this.salesGroups = result[1];

      this.users = result[2];

      this.initializePage = true;

      this.loadingService.hide();
    });
  }

  public submitUser(): void {
    this.user = this.usersService.submitUser(this.userBasicForm);

    this.commonService.insertObject('user', this.user).then((user) => {
      this.loadingService.hide();

      this.dialog.open(ActionCompletionDialogComponent, {
        data: {
          buttonText: 'Πλοήγηση στο χρήστη',
          redirectURL: `user/${user.id}`
        }
      });
    });
  }
}
