import {CanActivateChild, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MainGuardService implements CanActivateChild {
  constructor(private cookieService: CookieService,
              private router: Router) {
  }

  canActivateChild() {
    const authValidation = this.cookieService.get('Token');

    if (authValidation) {
      return true;
    }

    this.router.navigate(['login']);

    return false;
  }
}
