import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, Subscription} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {MatSnackBar, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '../services/auth.service';
import {MatDialog} from '@angular/material';
import {EventLogsService} from '../services/event-logs.service';
import {InventoriesService} from '../services/inventories.service';
import {Router} from '@angular/router';
import {SalePointsService} from '../services/sale-points.service';
import {LogoutDialogComponent} from '../logout-dialog/logout-dialog.component';
import {LoadingService} from '../services/loading.service';


@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
  public initializePage: boolean;
  private insertedInventories: Subscription;
  private insertedSalePoints: Subscription;
  public sidebarItems: any[];
  public totalNotifications: number;
  private updatedEventLogs: Subscription;
  public user: any;
  public itemSelected: number;
  public toolbarColor: string;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver,
              public loadingService: LoadingService,
              private authService: AuthService,
              private dialog: MatDialog,
              private eventLogsService: EventLogsService,
              private inventoriesService: InventoriesService,
              private router: Router,
              private salePointsService: SalePointsService,
              public snackBar: MatSnackBar) {
    this.toolbarColor = 'red';
    this.itemSelected = 0;
    this.sidebarItems = [
      {
        path: '/home',
        title: 'Αρχική',
        icon: 'home'
      },
      {
        path: '/refrigerators',
        title: 'Ψυγεία',
        icon: 'kitchen'
      }
    ];

    this.totalNotifications = 0;
  }
  isMenuOpen = true;
  contentMargin = 260;

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
    localStorage.setItem('menuState', JSON.stringify(this.isMenuOpen));
    if (!this.isMenuOpen) {
      this.contentMargin = 80;
    } else {
      this.contentMargin = 260;
    }
  }

  ngOnInit(): void {
    this.initializeFunctionality();
    try {
      this.isMenuOpen = JSON.parse(localStorage.getItem('menuState'));
    } catch (e) {
      this.isMenuOpen = true;
    }
  }

  public async initializeFunctionality() {
    const user = await this.authService.getUser();
    console.log(user);
    this.user = {
      description: user.role_name,
      name: `${user.name}`
    };
    if (user.role_alternate_name === 'basicUser' ||
      user.role_alternate_name === 'transportManager' ||
      user.role_alternate_name === 'driver' ||
      user.role_alternate_name === 'ownNetworkDistributor' ||
      user.role_alternate_name === 'ownNetworkRepresentative' ||
      user.role_alternate_name === 'districtRepresentative' ||
      user.role_alternate_name === 'technicalManager' ||
      user.role_alternate_name === 'technician' ||
      user.role_alternate_name === 'salesInspector') {
      this.sidebarItems.push({
        path: '/inventories',
        title: 'Απογραφές',
        icon: 'assignment'
      });
    }

    if (user.role_alternate_name === 'basicUser' ||
      user.role_alternate_name === 'transportManager' ||
      user.role_alternate_name === 'technicalManager' ||
      user.role_alternate_name === 'driver' ||
      user.role_alternate_name === 'ownNetworkDistributor' ||
      user.role_alternate_name === 'ownNetworkRepresentative' ||
      user.role_alternate_name === 'districtRepresentative' ||
      user.role_alternate_name === 'salesInspector' ||
      user.role_alternate_name === 'technician' ||
      user.role_alternate_name === 'regionalDirector') {
      this.sidebarItems.push({
        path: '/sale-points',
        title: 'Σημεία Πώλησης',
        icon: 'storefront'
      });
      this.sidebarItems.push({
        path: '/representatives',
        title: 'Αντιπρόσωποι',
        icon: 'storefront'
      });
    }

    if (user.role_alternate_name === 'basicUser' ||
      user.role_alternate_name === 'transportManager' ||
      user.role_alternate_name === 'technicalManager') {
      this.sidebarItems.push({
          path: '/purchase_orders',
          title: 'Παραγγελίες',
          icon: 'format_list_bulleted'
        },
        {
          path: '/purchase_order_requests',
          title: 'Αιτήσεις Ψυγείων',
          icon: 'format_list_numbered'
        },
        {
          path: '/loading_orders',
          title: 'Εντολές Φόρτωσης',
          icon: 'arrow_forward'
        },
        {
          path: '/receipt_orders',
          title: 'Εντολές Παραλαβής',
          icon: 'arrow_back'
        },
        {
          path: '/returns',
          title: 'Εντολές Επιστροφής',
          icon: 'assignment_return'
        },
        {
          path: '/statistics',
          title: 'Στατιστικά',
          icon: 'graphic_eq'
        },
        {
          path: '/refrigerator-budget',
          title: 'Budget Ψυγείων',
          icon: 'format_bold'
        }
      );
    }

    if (user.role_alternate_name === 'basicUser' || user.role_alternate_name === 'transportManager' || user.role_alternate_name === 'technicalManager') {
      this.sidebarItems.push({
        path: '/users',
        title: 'Χρήστες',
        icon: 'people'
      });
    }
    if (user.role_alternate_name === 'basicUser' || user.role_alternate_name === 'transportManager' || user.role_alternate_name === 'technicalManager') {
      this.eventLogsService.getEventLogsCount().then((totalNotifications) => {
        this.totalNotifications = totalNotifications[0].count;

        this.insertedInventories = this.inventoriesService.insertedInventories.subscribe((inventory) => {
          this.showSnackBar(inventory);
        });

        this.insertedSalePoints = this.salePointsService.insertedSalePoints.subscribe((salePoint) => {
          this.showSnackBar(salePoint);
        });

        this.updatedEventLogs = this.eventLogsService.updatedEventLogs.subscribe(() => {
          this.eventLogsService.getEventLogsCount().then((response) => {
            this.totalNotifications = response[0].count;
          });
        });
      });
    }
  }

  public showNotifications(): boolean {
   if (this.user) {
     return this.authService.isUserBelongsTo('basicUser') ||
       this.authService.isUserBelongsTo('transportManager') ||
       this.authService.isUserBelongsTo('technicalManager');
   } else {
     return false;
   }
  }

  private showSnackBar(eventLog: any): void {
    const snackBarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(eventLog.message,
      'Δείτε περισσότερα', {
        duration: 10000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
      });


    snackBarRef.onAction().subscribe(() => {
      this.router.navigate([eventLog.model, eventLog.model_id]);
    });
  }

  public logout(): void {
    this.dialog.open(LogoutDialogComponent);
  }

  public navigateToHelp(): void {
    this.router.navigate(['help']);
  }

  public navigateToNotifications(): void {
    this.router.navigate(['notifications']);
  }

  public navigateToUser(): void {
    this.router.navigate(['me']);
  }

  setSelectedItem(i: number) {
    console.log(i);
    this.itemSelected = i;
  }

  showSelection(i: any) {
    return i === this.itemSelected;
  }
}
