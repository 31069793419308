import {Component, OnInit} from '@angular/core';
import OrderDetailsConfig from '../../table/configs/purchase_order_details.json';
import {ActivatedRoute} from '@angular/router';
import {CreateLoadingRequestComponent} from '../../common/create-loading-request/create-loading-request.component';
import {AuthService} from '../../services/auth.service';
import {CommonService} from '../../services/common.service';
import {LoadingService} from '../../services/loading.service';
import {MatDialog} from '@angular/material';
import {CreateReceiptDialogComponent} from '../../users/user-details/create-receipt-dialog/create-receipt-dialog.component';
import {CreateUnloadingRequestComponent} from '../../common/create-unloading-request/create-unloading-request.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  public orderDetailsConfig: any;
  public purchaseDocument: string;

  public basicDetailsConfig: any;
  public showBasicInfo: boolean;
  private user: any;
  public order: any;

  constructor(public route: ActivatedRoute, private authService: AuthService, private dialog: MatDialog,
              private commonService: CommonService, public loadingService: LoadingService) {
    this.order = JSON.parse(localStorage.getItem('purchaseOrder'));
    this.purchaseDocument = this.order.purchasing_document;

    this.showBasicInfo = false;
    this.initUser();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.orderDetailsConfig = OrderDetailsConfig;
      this.orderDetailsConfig.filter = `po_id=${params.id}`;
    });
  }


  private async initUser() {
    this.user = await this.authService.getUser();
    this.initBasicInfo();
  }

  private async initBasicInfo() {
    this.basicDetailsConfig = {
      showActions: await this.commonService.showActions(this.user),
      subtitle: [
        {
          title: 'Αριθμός Παραγγελίας',
          value: this.purchaseDocument
        }
      ],
      title: 'Πληροφορίες Παραγγελίας',
      titleURL: false
    };

    this.initializeActions();
  }

  private initializeActions(): void {
    const actions = [];
    const action1 = {
      function: () => {
        this.initializeCreateLoadingRequest(1);
      },
      showAction: true,
      title: 'Δημιουργία Εντολής Φόρτωσης',
      icon: 'cloud_upload',
      color: 'green'
    };

    actions.push(action1);

    const action2 = {
      function: () => {
        this.initializeCreateUnloadingOrderDialog(1);
      },
      showAction: true,
      title: 'Δημιουργία Εντολής Παραλαβής',
      icon: 'cloud_download',
      color: '#266ccd'
    };

    actions.push(action2);
    if (actions.length > 0) {
      this.basicDetailsConfig.actions = actions;
      this.basicDetailsConfig.showActions = true;
    } else {
      this.basicDetailsConfig.showActions = false;
    }

    this.showBasicInfo = true;
  }

  private async initializeCreateLoadingRequest(type: number) {
    this.loadingService.show();
    const users = await this.commonService.getListWithParameters('user', 'role_id=2');
    const details = await this.commonService.getListWithParameters('po_details', `po_id=${this.order.id}`);
    this.loadingService.hide();
    this.dialog.open(CreateLoadingRequestComponent, {
      data: {
        users,
        details,
        order_id: this.order.id,
        type,
        purchaseDocument: this.purchaseDocument
      }
    });
  }

  private async initializeCreateUnloadingOrderDialog(type: number) {
    this.loadingService.show();
    const users = await this.commonService.getListWithParameters('user', 'role_id=6 OR role_id=13 OR role_id=14');
    const details = await this.commonService.getListWithParameters('po_details', `po_id=${this.order.id}`);
    const roles = await this.commonService.getListWithParameters('role', 'id=6 OR id=13 OR id=14');
    this.loadingService.hide();
    this.dialog.open(CreateUnloadingRequestComponent, {
      data: {
        orderType: 1,
        roles,
        users,
        details,
        type,
        order_id: this.order.id,
        purchaseDocument: this.purchaseDocument
      }
    });
  }
}
