import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LoadingService} from '../services/loading.service';
import LoadingOrdersConfig from '../table/configs/loading_orders.json';
import {MatTabGroup} from '@angular/material';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-loading-orders',
  templateUrl: './loading-orders.component.html',
  styleUrls: ['./loading-orders.component.scss']
})
export class LoadingOrdersComponent implements OnInit {

  public config: any;
  public initializePage: boolean;
  public OrdersToBeExecutedConfig: any;
  public OrdersToBeSentConfig: any;
  public OrdersSentConfig: any;

  public show0: boolean;
  public show1: boolean;
  public show2: boolean;

  @ViewChild('tabs', { static: false }) tabs: MatTabGroup;
  constructor(private loadingService: LoadingService, private authService: AuthService) {
    this.OrdersToBeExecutedConfig = (JSON.parse(JSON.stringify(LoadingOrdersConfig)));
    this.OrdersToBeExecutedConfig.filter = 'command_type=0 AND (command_details_total_quantity<>command_details_quantity_executed OR stage_id=1)';
    this.OrdersToBeSentConfig = (JSON.parse(JSON.stringify(LoadingOrdersConfig)));
    this.OrdersToBeSentConfig.filter = 'command_type = 0 AND stage_id = 2';
    this.OrdersSentConfig = (JSON.parse(JSON.stringify(LoadingOrdersConfig)));
    this.OrdersSentConfig.filter = 'command_type = 0 AND stage_id = 3';
    this.config = {
      title: 'Εντολές Φόρτωσης',
      model: 'loading_orders',
      nested: true
    };

    this.show0 = true;
    this.show1 = false;
    this.show2 = false;
  }



  public changeTab(tabs: MatTabGroup): void {
    if (tabs.selectedIndex === 0) {
      this.show0 = true;
      this.show1 = false;
      this.show2 = false;
    }
    if (tabs.selectedIndex === 1) {
      this.show0 = false;
      this.show1 = true;
      this.show2 = false;
    }
    if (tabs.selectedIndex === 2) {
      this.show0 = false;
      this.show1 = false;
      this.show2 = true;
    }
  }

  public show(tabs: MatTabGroup): number {
    return tabs.selectedIndex;
  }
  ngOnInit() {
    this.initializePage = true;
    this.loadingService.hide();
  }

}
