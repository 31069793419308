import { AdminGuardService } from './services/admin-guard.service';
import { AuthGuardService } from './services/auth-guard.service';
import { ErrorComponent } from './error/error.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';
import { InventoriesGuardService } from './services/inventories-guard.service';
import { InventoryDetailsComponent } from './inventory-details/inventory-details.component';
import { LoginComponent } from './login/login.component';
import { MainGuardService } from './services/main-guard.service';
import { LayoutComponent } from './layout/layout.component';
import { NgModule } from '@angular/core';
import { RefrigeratorDetailsComponent } from './refrigerator-details/refrigerator-details.component';
import { Routes, RouterModule } from '@angular/router';
import { SalePointDetailsComponent } from './sale-point-details/sale-point-details.component';
import { SalePointsGuardService } from './services/sale-points-guard.service';
import { TableComponent } from './table/table.component';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import {OrderDetailsComponent} from './purchase-orders/order-details/order-details.component';
import { UserFormComponent } from './users/user-form/user-form.component';
import EventLogsConfig from './table/configs/event-logs.json';
import InventoriesConfig from './table/configs/inventories.json';
import PurchaseOrdersConfig from './table/configs/purchaseOrders.json';
import OrderDetailsConfig from './table/configs/purchase_order_details.json';
import RefrigeratorsConfig from './table/configs/refrigerators.json';
import SalePointsConfig from './table/configs/sale-points.json';
import RepresentativesConfig from './table/configs/reprsentatives.json';
import UsersConfig from './table/configs/users.json';
import { PurchaseOrdersGuardService } from './services/purchase-orders-guard.service';
import {LoadingOrdersComponent} from './loading-orders/loading-orders.component';
import {ReceiptOrdersComponent} from './receipt-orders/receipt-orders.component';
import OrderRequests from './table/configs/orderRequests.json';
import ReturnCommands from './table/configs/returnCommands.json';
import {LoadingOrderDetailsComponent} from './loading-orders/loading-order-details/loading-order-details.component';
import {LoadingOrderDetailsInfoComponent} from './loading-orders/loading-order-details-info/loading-order-details-info.component';
import {ReceivingOrderDetailsComponent} from './receipt-orders/receiving-order-details/receiving-order-details.component';
import {ReceivingOrderDetailsInfoComponent} from './receipt-orders/receiving-order-details-info/receiving-order-details-info.component';
import {StatisticsComponent} from './statistics/statistics.component';
import {RequestOrderDetailsComponent} from './request-order-details/request-order-details.component';
import {ReturnRefrigeratorDetailsComponent} from './return-refrigerator-details/return-refrigerator-details.component';
import {RefrigeratorBudgetComponent} from './refrigerator-budget/refrigerator-budget.component';
import {MainNavComponent} from './main-nav/main-nav.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    canActivate: [AuthGuardService],
    component: LoginComponent
  },
  {
    path: '',
    component: MainNavComponent,
    canActivateChild: [MainGuardService],
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'help',
        component: HelpComponent
      },
      {
        path: 'me',
        component: UserDetailsComponent
      },
      {
        path: 'user-form',
        canActivate: [AdminGuardService],
        component: UserFormComponent
      },
      {
        path: 'inventory/:id',
        canActivate: [InventoriesGuardService],
        component: InventoryDetailsComponent
      },
      {
        path: 'refrigerator/:id',
        component: RefrigeratorDetailsComponent
      },
      {
        path: 'sale-point/:id',
        canActivate: [SalePointsGuardService],
        component: SalePointDetailsComponent
      },
      {
        path: 'user/:id',
        canActivate: [AdminGuardService],
        component: UserDetailsComponent
      },
      {
        path: 'inventories',
        canActivate: [InventoriesGuardService],
        component: TableComponent,
        data: { config: InventoriesConfig }
      },
      {
        path: 'loading_orders',
        canActivate: [PurchaseOrdersGuardService],
        component: LoadingOrdersComponent
      },
      {
        path: 'loading_order_details/:id',
        canActivate: [PurchaseOrdersGuardService],
        component: LoadingOrderDetailsComponent
      },
      {
        path: 'loading_order_details_info/:id',
        canActivate: [PurchaseOrdersGuardService],
        component: LoadingOrderDetailsInfoComponent
      },
      {
        path: 'receipt_orders',
        canActivate: [PurchaseOrdersGuardService],
        component: ReceiptOrdersComponent
      },
      {
        path: 'receiving_order_details/:id',
        canActivate: [PurchaseOrdersGuardService],
        component: ReceivingOrderDetailsComponent
      },
      {
        path: 'receiving_order_details_info/:id',
        canActivate: [PurchaseOrdersGuardService],
        component: ReceivingOrderDetailsInfoComponent
      },
      {
        path: 'purchase_orders',
        canActivate: [PurchaseOrdersGuardService],
        component: TableComponent,
        data: { config: PurchaseOrdersConfig }
      },
      {
        path: 'order_details/:id',
        canActivate: [PurchaseOrdersGuardService],
        component: OrderDetailsComponent,
        data: { config: OrderDetailsConfig }
      },
      {
        path: 'statistics',
        canActivate: [PurchaseOrdersGuardService],
        component: StatisticsComponent
      },
      {
        path: 'purchase_order_requests',
        canActivate: [PurchaseOrdersGuardService],
        component: TableComponent,
        data: { config: OrderRequests }
      },
      {
        path: 'returns',
        canActivate: [PurchaseOrdersGuardService],
        component: TableComponent,
        data: { config: ReturnCommands }
      },
      {
        path: 'return_refrigerator_details/:id',
        canActivate: [PurchaseOrdersGuardService],
        component: ReturnRefrigeratorDetailsComponent,
      },
      {
        path: 'request_order_details/:id',
        canActivate: [PurchaseOrdersGuardService],
        component: RequestOrderDetailsComponent,
      },
      {
        path: 'notifications',
        canActivate: [AdminGuardService],
        component: TableComponent,
        data: { config: EventLogsConfig }
      },
      {
        path: 'refrigerators',
        component: TableComponent,
        data: { config: RefrigeratorsConfig }
      },
      {
        path: 'sale-points',
        canActivate: [SalePointsGuardService],
        component: TableComponent,
        data: { config: SalePointsConfig }
      },
      {
        path: 'representatives',
        canActivate: [SalePointsGuardService],
        component: TableComponent,
        data: { config: RepresentativesConfig }
      },
      {
        path: 'refrigerator-budget',
        canActivate: [PurchaseOrdersGuardService],
        component: RefrigeratorBudgetComponent
      },
      {
        path: 'users',
        canActivate: [AdminGuardService],
        component: TableComponent,
        data: { config: UsersConfig }
      }
    ]
  },
  {
    path: 'access-denied',
    component: ErrorComponent,
    data: { mode: 'access-denied' }
  },
  {
    path: '**',
    component: ErrorComponent,
    data: { mode: 'error' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
