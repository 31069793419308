import {CommonService} from './common.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoadingService} from './loading.service';

@Injectable({
  providedIn: 'root'
})

export class UsersService {
  private serviceModel: string;

  constructor(private commonService: CommonService,
              private httpClient: HttpClient,
              private loadingService: LoadingService) {
    this.serviceModel = 'user';
  }

  public getUser(id: number): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(`${this.serviceModel}?first=true&filter=id=${id}`).subscribe((result) => {
        resolve(result);
      });
    });
  }

  public getUsers(): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(`${this.serviceModel}?filter=role_alternate_name = 'ownNetworkRepresentative' OR role_alternate_name = 'districtRepresentative'`)
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  public getChildren(id: number): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(`${this.serviceModel}?filter=representative_id = ${id}`)
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  public initializeRoles(roles: any[]): any[] {
    /* const regionalDirectorIndex = roles.findIndex((role) => {
       return role.alternate_name === 'regionalDirector';
     });
 
     roles.splice(regionalDirectorIndex, 1);
 
     const salesInspectorIndex = roles.findIndex((role) => {
       return role.alternate_name === 'salesInspector';
     });
 
     roles.splice(salesInspectorIndex, 1);*/

    return roles;
  }

  public initializeUser(user: any): any {
    const userData = {
      title: 'Στοιχεία Χρήστη',
      values: [
        {
          name: 'Όνοματεπώνυμο',
          value: this.commonService.setValue(user.name)
        },
        {
          name: 'Username',
          value: this.commonService.setValue(user.username)
        },
        {
          name: 'Password',
          value: this.commonService.setValue(user.password)
        }
      ]
    };

    if (user.role_alternate_name === 'driver' ||
      user.role_alternate_name === 'ownNetworkDistributor' ||
      user.role_alternate_name === 'ownNetworkRepresentative' ||
      user.role_alternate_name === 'technician' ||
      user.role_alternate_name === 'districtRepresentative') {
      let value = {
        name: 'Δρομολόγια',
        value: user.sales_group_names
      };

      userData.values.push(value);

      if (user.role_alternate_name === 'driver') {
        value = {
          name: 'Αντιπρόσωπος',
          value: user.representative_name
        };

        userData.values.push(value);
      }
    } else {
      const value: any = {
        phantom: true
      };

      userData.values.push(value);
    }

    return userData;
  }

  public initializeUserBasicForm(roles?: any[], user?: any): FormGroup {
    let userBasicForm: FormGroup;

    userBasicForm = new FormGroup({
      name: new FormControl(user && user.name ? user.name : '', Validators.required),
      password: new FormControl(user && user.password ? user.password : '', Validators.required),
      role: new FormControl(user ? this.commonService.findElement(user.role_id, roles) : '', Validators.required),
      status: new FormControl(user ? user.status_value : false),
      username: new FormControl(user && user.username ? user.username : '', Validators.required)
    });

    if (user) {
      userBasicForm = this.synchronizeUserBasicForm(userBasicForm, user);
    }

    return userBasicForm;
  }

  public initializeUserForm(): Array<Promise<any>> {
    let promises: Array<Promise<any>>;

    promises = [];

    const promise1 = this.commonService.getList('role');

    promises.push(promise1);

    const promise2 = this.commonService.getList('sales_group');

    promises.push(promise2);

    const promise3 = this.getUsers();

    promises.push(promise3);

    return promises;
  }

  public isUserFormValid(userBasicForm: FormGroup): boolean {
    return !userBasicForm.valid;
  }

  public showSalesGroup(userBasicForm: FormGroup): boolean {
    if (userBasicForm.get('role').value) {
      return userBasicForm.get('role').value.alternate_name === 'driver' ||
        userBasicForm.get('role').value.alternate_name === 'ownNetworkDistributor' ||
        userBasicForm.get('role').value.alternate_name === 'ownNetworkRepresentative' ||
        userBasicForm.get('role').value.alternate_name === 'technician' ||
        userBasicForm.get('role').value.alternate_name === 'districtRepresentative';
    } else {
      return false;
    }
  }

  public showRepresentative(userBasicForm: FormGroup): boolean {
    return userBasicForm.get('role').value ? userBasicForm.get('role').value.alternate_name === 'driver' : false;
  }

  public submitUser(userBasicForm: FormGroup, user?: any): any {
    this.loadingService.show();

    let draftUser: any;

    draftUser = {
      name: userBasicForm.get('name').value.toUpperCase(),
      password: userBasicForm.get('password').value,
      role_alternate_name: userBasicForm.get('role').value.alternate_name,
      role_id: userBasicForm.get('role').value.id,
      username: userBasicForm.get('username').value
    };

    if (this.showSalesGroup(userBasicForm)) {
      draftUser.salesGroups = userBasicForm.get('salesGroups').value;
    }

    if (this.showRepresentative(userBasicForm)) {
      draftUser.representative_id = userBasicForm.get('representative').value;
    } else {
      draftUser.representative_id = null;
    }

    if (user) {
      draftUser.id = user.id;

      draftUser.status = userBasicForm.get('status').value;
    }

    return draftUser;
  }

  public synchronizeUserBasicForm(userBasicForm: FormGroup, user?: any): FormGroup {
    if (this.showSalesGroup(userBasicForm) && !userBasicForm.contains('salesGroups')) {
      userBasicForm.addControl('salesGroups', new FormControl(user && user.sales_group_ids ?
        user.sales_group_ids.split(',').map((id) => id) : '', Validators.required));
    } else if (!this.showSalesGroup(userBasicForm) && userBasicForm.contains('salesGroups')) {
      userBasicForm.removeControl('salesGroups');
    }

    if (this.showRepresentative(userBasicForm) && !userBasicForm.contains('representative')) {
      userBasicForm.addControl('representative', new FormControl(user && user.representative_id ?
        user.representative_id : '', Validators.required));
    } else if (!this.showRepresentative(userBasicForm) && userBasicForm.contains('representative')) {
      userBasicForm.removeControl('representative');
    }

    return userBasicForm;
  }
}
