import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  templateUrl: './inventory-refrigerators-dialog.component.html',
  styleUrls: ['./inventory-refrigerators-dialog.component.scss']
})

export class InventoryRefrigeratorsDialogComponent {
  public refrigeratorsConfig: any;

  constructor(private dialogRef: MatDialogRef<InventoryRefrigeratorsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.refrigeratorsConfig = this.data.refrigeratorsConfig;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
