import { ActionCompletionDialogComponent } from '../../common/action-completion-dialog/action-completion-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';
import { Component, OnInit } from '@angular/core';
import { ConfirmActionDialogComponent } from '../../common/confirm-action-dialog/confirm-action-dialog.component';
import { CreateInventoryDialogComponent } from './create-inventory-dialog/create-inventory-dialog.component';
import { Details } from '../../details/details';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { LoadingService } from '../../services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { SalePointsService } from '../../services/sale-points.service';
import { UsersService } from '../../services/users.service';
import * as moment from 'moment';

import InventoriesConfig from './tables-configs/inventories.json';
import SalePointsConfig from './tables-configs/sale-points.json';
import {CreateLoadingDialogComponent} from './create-loading-dialog/create-loading-dialog.component';
import {PurchaseOrdersService} from '../../services/purchase-orders.service';
import {CreateReceiptDialogComponent} from './create-receipt-dialog/create-receipt-dialog.component';
import {AssignAndDetailModalComponent} from '../../common/assign-and-detail-modal/assign-and-detail-modal.component';

@Component({
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})

export class UserDetailsComponent extends Details implements OnInit {
  public basicDetailsConfig: any;
  public children: any;
  private initializeEditForm: boolean;
  public initializePage: boolean;
  public inventoriesConfig: any;
  public mainDataSections: any[];
  private loggedInUser: any;
  public roles: any[];
  public salePointsConfig: any;
  public salesGroups: any[];
  private user: any;
  public users: any[];

  constructor(private authService: AuthService,
              public commonService: CommonService,
              private dialog: MatDialog,
              private loadingService: LoadingService,
              private purchaseOrdersService: PurchaseOrdersService,
              private route: ActivatedRoute,
              private router: Router,
              private salePointsService: SalePointsService,
              private usersService: UsersService) {
    super();

    this.inventoriesConfig = InventoriesConfig;

    this.salePointsConfig = SalePointsConfig;
  }

  ngOnInit(): void {
    let promise: Promise<any>;
    const currentUserPromise = this.authService.getUser();
    if (this.router.url === '/me') {
      promise = this.authService.getUser();
    } else {
      this.route.paramMap.subscribe((params) => {
        promise = this.usersService.getUser(+params.get('id'));
      });
    }

    currentUserPromise.then(user => {
      this.loggedInUser = user;
    });

    promise.then((user) => {
      this.user = user;

      this.inventoriesConfig.filter = 'user_id = ' + this.user.id;

      if (this.user.role_alternate_name === 'regionalDirector') {
        this.salePointsConfig.filter = 'regional_id = ' + this.user.id;
      } else if (this.user.role_alternate_name === 'salesInspector') {
        this.salePointsConfig.filter = 'inspector_id = ' + this.user.id;
      } else {
        this.salePointsConfig.filter = 'user_id = ' + this.user.id;
      }

      this.basicDetailsConfig = {
        showActions: this.showActions(),
        status_name: this.user.status_name,
        status_value: this.user.status_value,
        subtitle: [
          {
            title: 'Ρόλος',
            value: this.user.role_name
          }
        ],
        title: 'Πληροφορίες Χρήστη'
      };

      this.initializeActions();

      this.mainDataSections = [this.usersService.initializeUser(this.user)];

      this.removePasswordFromMainDataSections();

      this.initializePage = true;

      this.loadingService.hide();
    });
  }

  public changeTab(tabs: MatTabGroup): void {
    if (tabs.selectedIndex !== 0) {
      this.loadingService.show();
    }
  }

  private initializeActions(): void {
    const actions = [];
    if (this.showEditInfoAction()) {
      const action = {
        function: () => {
          this.initializeEditUserForm();
        },
        showAction: true,
        title: 'Επεξεργασία Στοιχείων',
        icon: 'edit',
        color: 'black'
      };
      actions.push(action);
    }

    if (this.showCreateInventoryAction()) {
      if (this.user.role_alternate_name === 'driver' ||
        this.user.role_alternate_name === 'ownNetworkDistributor' ||
        this.user.role_alternate_name === 'ownNetworkRepresentative' ||
        this.user.role_alternate_name === 'technician' ||
        this.user.role_alternate_name === 'districtRepresentative') {
        const action = {
          function: () => {
            this.initializeCreateInventoryDialog();
          },
          showAction: true,
          title: 'Δημιουργία Απογραφής',
          icon: 'assignment',
          color: '#f7b733'
        };

        actions.push(action);
      }
    }

    if (this.showAssignReturnCommand()) {
      if (this.user.role_alternate_name === 'warehouseManager' ) {
          const action = {
            function: () => {
              this.initializeCreateAssignment();
            },
            showAction: true,
            title: 'Ανάθεση Εντολής Επιστροφής',
            icon: 'assignment',
            color: '#266ccd'
          };
          actions.push(action);
      }
    }

    if (this.showCreateLoadingAndReceiptOrder()) {
      if (this.user.role_alternate_name === 'manufacturingCompanyRepresentative' ) {
        const action2 = {
          function: () => {
            this.initializeCreateLoadingOrderDialog(2);
          },
          showAction: true,
          title: 'Δημιουργία Εντολής Φόρτωσης από Αίτηση',
          icon: 'cloud_upload',
          color: '#266ccd'
        };
        actions.push(action2);
        const action = {
          function: () => {
            this.initializeCreateLoadingOrderDialog(1);
          },
          showAction: true,
          title: 'Δημιουργία Εντολής Φόρτωσης από Παραγγελία',
          icon: 'cloud_upload',
          color: 'green'
        };

        actions.push(action);
      }

      if (this.user.role_alternate_name === 'manager3PL' ||
        this.user.role_alternate_name === 'districtRepresentative' ||
        this.user.role_alternate_name === 'warehouseManager' ) {

        if (this.user.role_alternate_name === 'manager3PL' ||
          this.user.role_alternate_name === 'warehouseManager' ) {
          const action2 = {
            function: () => {
              this.initializeCreateLoadingOrderDialog(2);
            },
            showAction: true,
            title: 'Δημιουργία Εντολής Φόρτωσης από Αίτηση',
            icon: 'cloud_upload',
            color: '#266ccd'
          };
          actions.push(action2);
        }

        if (this.user.role_alternate_name === 'districtRepresentative') {
          const action3 = {
            function: () => {
              this.initializeCreateReceiptOrderDialog(2);
            },
            showAction: true,
            title: 'Δημιουργία Εντολής Παραλαβής από Αίτηση',
            icon: 'cloud_download',
            color: '#266ccd'
          };

          actions.push(action3);
        }

        const action = {
          function: () => {
            this.initializeCreateReceiptOrderDialog(1);
          },
          showAction: true,
          title: 'Δημιουργία Εντολής Παραλαβής από Παραγγελία',
          icon: 'cloud_download',
          color: 'green'
        };

        actions.push(action);
      }
    }
    if (actions.length > 0) {
      this.basicDetailsConfig.actions = actions;
      this.basicDetailsConfig.showActions = true;
    } else {
      this.basicDetailsConfig.showActions = false;
    }
  }

  private initializeCreateInventoryDialog(): void {
    this.loadingService.show();

    let filter: string;

    if (this.user.role_alternate_name === 'regionalDirector') {
      filter = 'regional_id = ' + this.user.id;
    } else if (this.user.role_alternate_name === 'salesInspector') {
      filter = 'inspector_id = ' + this.user.id;
    } else {
      filter = 'user_id = ' + this.user.id;
    }

    this.salePointsService.getUserSalePointsCount(filter).then((salePointsCount) => {
      if (salePointsCount[0].count > 0) {
        this.salePointsService.getUserSalePoints(filter).then((salePoints) => {
          this.loadingService.hide();

          this.dialog.open(CreateInventoryDialogComponent, {
            data: {
              salePoints,
              user: this.user
            }
          });
        });
      } else {
        this.loadingService.hide();

        this.dialog.open(ConfirmActionDialogComponent, {
          data: {
            confirmFunction: () => {
              this.loadingService.show();

              const inventory = {
                date_created: moment().format('YYYY-MM-DD'),
                user: {
                  id: this.user.id,
                  role_alternate_name: this.user.role_alternate_name
                }
              };

              this.commonService.insertObject('inventory/web', inventory).then((response) => {
                this.dialog.open(ActionCompletionDialogComponent, {
                  data: {
                    buttonText: 'Πλοήγηση στην απογραφή',
                    redirectURL: `inventory/${response.id}`
                  }
                });

                this.loadingService.hide();
              });
            },
            message: 'Είστε σίγουροι πως θέλετε να δημιουργήσετε απογραφή για το συγκεκριμένο χρήστη;',
            title: 'Δημιουργία Απογραφής'
          }
        });
      }
    });
  }

  private initializeEditUserForm(): void {
    if (this.initializeEditForm) {
      this.openEditForm();
    } else {
      this.loadingService.show();

      Promise.all(this.usersService.initializeUserForm()).then((result) => {
        this.roles = this.usersService.initializeRoles(result[0]);

        this.salesGroups = result[1];

        this.users = result[2];

        this.initializeEditForm = true;

        this.usersService.getChildren(this.user.id).then(data => {
          this.children = data;
          this.loadingService.hide();

          this.openEditForm();
        });
      });
    }
  }

  private openEditForm(): void {
    this.dialog.open(EditUserDialogComponent, {
      data: {
        roles: this.roles,
        salesGroups: this.salesGroups,
        user: this.user,
        users: this.users,
        hasChildren: this.children.length > 0 ? true : false
      }
    });
  }

  removePasswordFromMainDataSections(): void {
    const index = this.mainDataSections[0].values.findIndex(sectionValue => sectionValue.name === 'Password');
    if (index !== -1) {
      this.mainDataSections[0].values.splice(index, 1);
    }
  }

  public showEditInfoAction(): boolean {
    return this.loggedInUser.role_alternate_name === 'basicUser';
  }


  public showCreateInventoryAction(): boolean {
    return this.loggedInUser.role_alternate_name === 'basicUser' ||
      this.loggedInUser.role_alternate_name === 'transportManager' ||
      this.loggedInUser.role_alternate_name === 'technicalManager';
  }

  public showAssignReturnCommand(): boolean {
    return this.loggedInUser.role_alternate_name === 'basicUser' ||
      this.loggedInUser.role_alternate_name === 'transportManager';
  }

  public showCreateLoadingAndReceiptOrder(): boolean {
    return this.loggedInUser.role_alternate_name === 'basicUser' ||
      this.loggedInUser.role_alternate_name === 'transportManager' ||
      this.loggedInUser.role_alternate_name === 'technicalManager';
  }

  protected showActions(): boolean {
    return (this.authService.isUserBelongsTo('basicUser') ||
      this.authService.isUserBelongsTo('transportManager') || this.authService.isUserBelongsTo('technicalManager')) &&
      (this.user.role_alternate_name !== 'regionalDirector' && this.user.role_alternate_name !== 'salesInspector');
  }

  public showInventoriesTab(): boolean {
    return this.user.role_alternate_name === 'driver' ||
      this.user.role_alternate_name === 'ownNetworkDistributor' ||
      this.user.role_alternate_name === 'ownNetworkRepresentative' ||
      this.user.role_alternate_name === 'districtRepresentative' ||
      this.user.role_alternate_name === 'technician' ||
      this.user.role_alternate_name === 'salesInspector';
  }

  public showSalePointsTab(): boolean {
    return this.user.role_alternate_name === 'driver' ||
      this.user.role_alternate_name === 'ownNetworkDistributor' ||
      this.user.role_alternate_name === 'ownNetworkRepresentative' ||
      this.user.role_alternate_name === 'districtRepresentative' ||
      this.user.role_alternate_name === 'salesInspector' ||
      this.user.role_alternate_name === 'technician' ||
      this.user.role_alternate_name === 'regionalDirector';
  }

  private initializeCreateLoadingOrderDialog(type: number): void {
    this.loadingService.show();
    let request: any = {};
    request = type === 1 ? {url: `purchase_orders`, filter: `vendor_user_id=${this.user.id}`} : {url: `request/web`};

    this.purchaseOrdersService.getList(request.url, request.filter).then(purchaseOrders => {
      this.loadingService.hide();
      this.dialog.open(CreateLoadingDialogComponent, {
        data: {
          type,
          orders : purchaseOrders,
          user: this.user
        }
      });
    });
  }

  private initializeCreateAssignment() {
    this.loadingService.show();
    const filter = `user_id=${this.user.id} AND category_id = 2`;
    this.purchaseOrdersService.getList('sale_point', filter).then(salePoint => {
      this.loadingService.hide();
      this.dialog.open(AssignAndDetailModalComponent, {
        data: {
          type: 1,
          userSalePoint: salePoint,
          user: this.user
        }
      });
    });
  }

  private initializeCreateReceiptOrderDialog(type: number) {
    this.loadingService.show();
    let filter = '';
    if (type === 1) {
      switch (this.user.role_alternate_name) {
        case 'districtRepresentative':
          filter = `user_id=${this.user.id} AND category_id = 4`;
          break;
        case 'manager3PL':
          filter = `user_id=${this.user.id} AND category_id = 3`;
          break;
        case 'warehouseManager':
          filter = `user_id=${this.user.id} AND category_id = 2`;
          break;
        default:
          filter = `user_id=${this.user.id}`;
          break;
      }
    } else {
      filter = `user_id=${this.user.id} AND category_id = 4`;
    }

    this.purchaseOrdersService.getList('sale_point', filter).then(salePoint => {
     this.loadingService.hide();
     this.dialog.open(CreateReceiptDialogComponent, {
       data: {
         type,
         userSalePoint: salePoint,
         user: this.user
       }
     });
   });
  }
}
