import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatStepper, MatTableDataSource} from '@angular/material';
import {IOrderDetails, IOrderRequestDetails, IReceivingOrderDetails, ISalePoint, IVendor} from '../../../models/models';
import {PurchaseOrdersService} from '../../../services/purchase-orders.service';
import {SelectionModel} from '@angular/cdk/collections';
import * as moment from 'moment';
import {ActionCompletionDialogComponent} from '../../../common/action-completion-dialog/action-completion-dialog.component';
import {BehaviorSubject} from 'rxjs';


@Component({
  selector: 'app-create-receipt-dialog',
  templateUrl: './create-receipt-dialog.component.html',
  styleUrls: ['./create-receipt-dialog.component.scss']
})
export class CreateReceiptDialogComponent implements OnInit {
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public loading: boolean;
  private selectedCommandId: number;
  public minDate: Date;
  public orders: any;
  private columnsForNewCommand: string[] = ['select', 'material_id', 'material_description', 'quantity_ordered', 'loaded_quantity', 'loading_quantity'];
  private columnsForTransformedCommand: string[] = ['select', 'material_id', 'material_description', 'total_quantity', 'loaded_quantity', 'receiving_quantity'];
  public displayedColumns: string[];
  public orderType: number;
  private user: any;
  public selectedDeliveryPointId: number;
  public vendors: IVendor[];
  public poDetailsDataSource = new MatTableDataSource<IOrderDetails>();
  public loadOrderDetailsDataSource = new MatTableDataSource<IReceivingOrderDetails>();
  public ordersDataSource: any;
  public selection;
  public selectedOrderId: number;
  public title: string;
  public type: number;
  private userSalePoint: any;
  public deliveryPointsRecipients: any[] = [];
  public deliveryPointsDataSource: ISalePoint[];
  public total = 100;
  public limit = 10;
  public offset = 0;
  public options = new BehaviorSubject<any>({refresh: true, data: []});
  public options$: ISalePoint[];
  @ViewChild('stepper', {static: false}) stepper: MatStepper;

  constructor(private purchaseOrdersService: PurchaseOrdersService,
              private dialogRef: MatDialogRef<CreateReceiptDialogComponent>,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = 'Δημιουργία Εντολής Παραλαβής';
    this.type = this.data.type;
    this.loading = false;
    this.user = this.data.user;
    this.minDate = new Date();
    this.orderType = 0;
    this.options$ = [];
    this.ordersDataSource = [];
    this.deliveryPointsDataSource = [];
    this.deliveryPointsRecipients = [];

    this.userSalePoint = this.data.userSalePoint;

    this.firstFormGroup = new FormGroup({
      order_type: new FormControl('', [Validators.required])
    });

    if (this.type === 1) {
      this.secondFormGroup = new FormGroup({
        vendors: new FormControl({value: '', disable: false}, [Validators.required]),
        sent_date: new FormControl({value: '', disable: false}, Validators.required),
        orders: new FormControl({value: '', disable: false}, Validators.required)
      });
    } else {
      this.secondFormGroup = new FormGroup({
        vendors: new FormControl({value: '', disable: false}, [Validators.required]),
        sent_date: new FormControl({value: '', disable: false}, Validators.required),
        orders: new FormControl({value: '', disable: false}, Validators.required),
        delivery_point_recipient: new FormControl({value: '', disable: false}, Validators.required),
        delivery_point: new FormControl({value: '', disable: false}, Validators.required)
      });
    }
  }

  ngOnInit() {
    this.thirdFormGroup = new FormGroup({});

    this.showLoading(true);
    if (this.type === 2) {
      this.purchaseOrdersService.getList('sale_point_category').then(data => {
        this.deliveryPointsRecipients = data.filter(recipient => recipient.id !== 1 && recipient.id !== 4);
        this.showLoading(false);
      });

      this.options.asObservable().subscribe((dataNext: any) => {

        if (dataNext.refresh === true) {
          this.options$ = dataNext.data;
        } else {
          this.options$ = [...this.options$, ...dataNext.data];
        }
      });
    }
  }

  public deliveryPointSelected(): void {
    const selectedRecipient = this.secondFormGroup.get('delivery_point_recipient').value;

    this.selectedDeliveryPointId = selectedRecipient.id;
    if (selectedRecipient) {
      this.deliveryPointsDataSource = [];
      this.secondFormGroup.controls.delivery_point.reset();
      this.showLoading(true);
      let request: any;
      if (selectedRecipient.id === 5) {
        request = {url: `vendor_user`, filter: null};
      } else {
        request = {url: 'sale_point', filter: `category_id=${selectedRecipient.id} OR user_id=NULL`};
      }
      this.purchaseOrdersService.getList(request.url, request.filter).then((data: any) => {
        // data.sort((a, b) => (a.customer_name > b.customer_name) ? 1 : ((b.customer_name > a.customer_name) ? -1 : 0));
        this.deliveryPointsDataSource = data;
        this.offset = 0;
        this.getNextBatch();
        this.showLoading(false);
      });
    } else {
      this.ordersDataSource = [];
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public getNextBatch(): void {
    this.showLoading(true);
    const result: ISalePoint[] = this.deliveryPointsDataSource.slice(this.offset, this.offset + this.limit);

    if (this.offset === 0) {
      this.options.next({refresh: true, data: result});
    } else {
      this.options.next({refresh: false, data: result});
    }
    this.offset += this.limit;
    this.showLoading(false);
  }

  private loadForm(): void {
    this.showLoading(true);
    this.purchaseOrdersService.getList('vendor_user').then((vendors: IVendor[]) => {
      this.vendors = vendors;
      this.showLoading(false);
    });
  }

  public next() {
    this.stepper.next();
    if (this.stepper.selectedIndex === 1) {
      // tslint:disable-next-line:radix
      this.orderType = parseInt(this.firstFormGroup.get('order_type').value);
      this.title = this.orderType === 1 ? 'Μετατροπή εντολής φόρτωσης προς δημιουργία εντολής παραλαβής' : 'Δημιουργία νέας εντολής παραλαβής';
      this.loadForm();
    }

    if (this.stepper.selectedIndex === 2) {

      if (this.type === 1) {

        this.showLoading(true);
        console.log(this.orderType);
        const selectedOrder = this.secondFormGroup.get('orders').value;
        this.selectedOrderId = selectedOrder.id;
        if (this.orderType === 2) {
          this.selection = new SelectionModel<IOrderDetails>(true, []);
          this.displayedColumns = this.columnsForNewCommand;
          this.purchaseOrdersService.getList('po_details', `po_id=${selectedOrder.id}`, false).then(data => {
            console.log(data);
            this.poDetailsDataSource.data = data;
            this.showLoading(false);
          });
        }
        if (this.orderType === 1) {
          this.selection = new SelectionModel<IReceivingOrderDetails>(true, []);
          this.displayedColumns = this.columnsForTransformedCommand;
          this.purchaseOrdersService.getList('loading_command/web/details', `command_id=${selectedOrder.id}`, true).then(data => {
            data.value.forEach(d => {
              d.loaded_quantity = d.loaded_quantity === null ? 0 : d.loaded_quantity;
            });
            data.value.forEach(d => {
              d.quantity_left = d.total_quantity - d.loaded_quantity;
            });
            this.loadOrderDetailsDataSource.data = data.value;
            this.showLoading(false);
          });
        }
      } else {
        const selectedOrder = this.secondFormGroup.get('orders').value;
        this.selectedOrderId = selectedOrder.id;
        if (this.orderType === 2) {
          this.selection = new SelectionModel<IOrderDetails>(true, []);
          this.displayedColumns = this.columnsForNewCommand;
          this.purchaseOrdersService.getList('request/web/details', `request_id=${selectedOrder.id}`, false).then((data: any) => {
            data.forEach(d => {
              d.quantity_ordered = d.quantity;
            });
            this.poDetailsDataSource.data = data;
            this.showLoading(false);
          });
        } else {
          this.selection = new SelectionModel<IOrderRequestDetails>(true, []);
          this.displayedColumns = this.columnsForTransformedCommand;
          this.purchaseOrdersService.getList('loading_command/web/details', `command_id=${selectedOrder.id}`, true).then((data: any) => {
            data.value.forEach(d => {
              d.loaded_quantity = d.loaded_quantity === null ? 0 : d.loaded_quantity;
            });
            data.value.forEach(d => {
              d.quantity_ordered = d.total_quantity;
              d.quantity_ordered = d.quantity;
            });
            // this.poDetailsDataSource.data = data;
            this.loadOrderDetailsDataSource.data = data.value;
            this.showLoading(false);
          });

        }
      }
    }
  }

  public previous() {
    this.stepper.previous();
    if (this.stepper.selectedIndex === 0) {
      this.secondFormGroup.reset();
    }
  }

  public show(type: number): boolean {
    return this.orderType === type;
  }

  public showLoading(action: boolean): void {
    this.loading = action;
    const group = this.secondFormGroup;

    if (action) {
      group.disable();
    } else {
      group.enable();
    }
  }

  public submitReceipt(): void {
    this.showLoading(true);
    let userId: number;

    userId = this.type === 1 ? this.secondFormGroup.get('vendors').value.user_id : this.secondFormGroup.get('delivery_point').value.user_id;
    let unloadingModel: any;

    if (this.type === 1) {
       unloadingModel = {
        user_id: userId,
        order_id: this.selectedOrderId,
        order_type_id: 1,
        sale_point_id: this.userSalePoint[0].id,
        desired_delivery_date: moment(this.secondFormGroup.get('sent_date').value).format('YYYY-MM-DD'),
        command_type: 1,
        command_id: this.orderType === 1 ? this.selectedOrderId : null,
        materials: []
      };
    }
    if (this.type === 2) {
      unloadingModel = {
        user_id: userId,
        order_id: this.selectedOrderId,
        order_type_id: 2,
        sale_point_id: this.userSalePoint[0].id,
        desired_delivery_date: moment(this.secondFormGroup.get('sent_date').value).format('YYYY-MM-DD'),
        command_type: 1,
        command_id: this.orderType === 1 ? this.selectedOrderId : null,
        materials: []
      };
    }


    this.selection.selected.forEach(selectedItem => {
      const model = {
        material_id: selectedItem.material_id,
        total_quantity: this.orderType === 1 ? selectedItem.receiving_quantity : selectedItem.loading_quantity
      };
      unloadingModel.materials.push(model);
    });

    console.log(unloadingModel);

    this.purchaseOrdersService.storeLoadingCommand('unloading_command', unloadingModel).then(() => {
      this.showLoading(false);
      this.dialogRef.close();

      this.dialog.open(ActionCompletionDialogComponent, {
        data: {
          buttonText: 'Πλοήγηση στις παραλαβές',
          redirectURL: `receipt_orders`
        }
      });
    });
  }

  public vendorSelected(): void {
    this.showLoading(true);
    const selectedVendor = this.secondFormGroup.get('vendors').value;
    if (selectedVendor) {
      const model = this.orderType === 1 ? {
        name: 'loading_command/web',
        filter: `user_id = ${selectedVendor.user_id} AND order_type_id = 1 AND command_type = 0 AND (status_id = 1 OR status_id = 2) AND sale_point_id = ${this.userSalePoint[0].id}`,
        options: true
      } : {name: 'purchase_orders', filter: `vendor_user_id = ${selectedVendor.user_id}`, options: true};
      this.purchaseOrdersService.getList(model.name, model.filter, model.options).then(response => {
        this.orders = response.value;
        this.showLoading(false);
      });
    } else {
      this.showLoading(false);
    }
  }

  public updateData(index: number, event: any): void {
    const value = event.target.value;
    if (index !== -1) {
      this.poDetailsDataSource.data[index].loading_quantity = value ? value : '';
    }
  }

  public updateDataOrders(index: number, event: any): void {
    const value = event.target.value;
    if (index !== -1) {
      this.loadOrderDetailsDataSource.data[index].receiving_quantity = value ? value : '';
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.poDetailsDataSource.data.length;
    return numSelected === numRows;
  }

  isAllSelectedOrders() {
    const numSelected = this.selection.selected.length;
    const numRows = this.loadOrderDetailsDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.poDetailsDataSource.data.forEach(row => this.selection.select(row));
  }

  masterToggleOrders() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.loadOrderDetailsDataSource.data.forEach(row => this.selection.select(row));
  }

  public isLoadingValid(): boolean {
    let valid = true;
    this.selection.selected.forEach(selectedItem => {
      const quantity =  this.orderType === 1 ? selectedItem.receiving_quantity : selectedItem.loading_quantity;
      if (quantity === null || !quantity) {
        valid = false;
      }
    });
    // return valid && this.selection.selected.length > 0;
    if (valid && this.selection.selected.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: IOrderDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  checkboxLabelOrders(row?: IReceivingOrderDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  public deliveryPointChanged() {
    const select = this.secondFormGroup.get('delivery_point').value;
    let request: any = {};
    request = this.orderType === 2 ? {url: `request/web`, filter: `(status_id = 6 OR status_id = 7) AND sale_point_category_id = 4 AND sale_point_id = ${this.userSalePoint[0].id}`} : {url: `loading_command/web`, filter:  `user_id=${select.user_id} AND order_type_id = 2 AND  command_type = 0 AND (status_id = 1 OR status_id = 2) AND sale_point_id = ${this.userSalePoint[0].id}`};

    this.purchaseOrdersService.getList(request.url, request.filter, this.orderType === 1 ).then((data: any) => {
      this.orders = this.orderType === 2 ? data : data.value;
      this.showLoading(false);
    });
  }

  public requestOrderChanged() {
    const selectedOrder = this.secondFormGroup.get('orders').value;

    this.secondFormGroup.controls.sent_date.setValue(new Date(selectedOrder.desired_delivery_date));
  }
}
