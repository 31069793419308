import {Component, OnInit} from '@angular/core';

import {ActivatedRoute} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {IReturnCommandDetails} from '../models/models';
import {PurchaseOrdersService} from '../services/purchase-orders.service';
import {LoadingService} from '../services/loading.service';
import {ConfirmActionDialogComponent} from '../common/confirm-action-dialog/confirm-action-dialog.component';
import {MatDialog} from '@angular/material';
import {ActionCompletionDialogComponent} from '../common/action-completion-dialog/action-completion-dialog.component';
import {AssignAndDetailModalComponent} from '../common/assign-and-detail-modal/assign-and-detail-modal.component';
import {AuthService} from '../services/auth.service';
import {CommonService} from '../services/common.service';
import {AssignRequestComponent} from './assign-request/assign-request.component';

@Component({
  selector: 'app-return-refrigerator-details',
  templateUrl: './return-refrigerator-details.component.html',
  styleUrls: ['./return-refrigerator-details.component.scss']
})
export class ReturnRefrigeratorDetailsComponent implements OnInit {
  public dataSource: IReturnCommandDetails[];
  public displayedColumns: string[];
  public selection: SelectionModel<IReturnCommandDetails>;
  private model: string;
  private returnId: string;
 public returnDetails: any;
  public basicDetailsConfig: any;
  public showBasicInfo: boolean;
  private user: any;

  constructor(public route: ActivatedRoute, public loadingService: LoadingService, private authService: AuthService,
              private commonService: CommonService,
              private purchaseOrdersService: PurchaseOrdersService,  private dialog: MatDialog) {
    this.model = 'return_command/web/details';
    this.selection = new SelectionModel<IReturnCommandDetails>(true, []);
    this.returnDetails = JSON.parse(localStorage.getItem('returnDetails'));

    this.showBasicInfo = false;
    this.initUser();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.returnId = params.id;
      if (this.returnDetails.status_id === 5) {
        this.displayedColumns = ['select', 'serial_number', 'material_id', 'material_description', 'is_confirmed_desc', 'is_received_desc', 'is_withdrawn_desc', 'latitude', 'longitude'];
      } else {
        this.displayedColumns = ['serial_number', 'material_id', 'material_description', 'is_confirmed_desc', 'is_received_desc', 'is_withdrawn_desc', 'latitude', 'longitude'];
      }

      if (this.returnDetails.typeId === 6) {
        this.displayedColumns.push('sales_inspector_action_description');
      }
     // this.displayedColumns = ['select', 'id', 'serial_number', 'material_id', 'material_description', 'latitude', 'longitude'];

      this.purchaseOrdersService.getList(`${this.model}`, `return_command_id=${this.returnId}`, false)
        .then((data: IReturnCommandDetails[]) => {
        data.forEach(detail => {
          detail.is_confirmed_desc = detail.is_confirmed === 1 ? '√' : 'X';
          detail.is_received_desc = detail.is_received === 1 ? '√' : 'X';
          detail.is_withdrawn_desc = detail.is_withdrawn === 1 ? '√' : 'X';
        });
        this.dataSource = data;
        this.loadingService.hide();
      });
    });
  }


  private async initUser() {
    this.user = await this.authService.getUser();
    this.initBasicInfo();
  }

  private async initBasicInfo() {
    this.showBasicInfo = true;
    this.basicDetailsConfig = {
      showActions: await this.commonService.showActions(this.user),
      subtitle: [
        {
          title: 'Αριθμός Εντολής Επιστροφής',
          value: this.returnDetails.requestId
        }
      ],
      title: 'Πληροφορίες Αίτησης'
    };

    this.initializeActions();
  }

  private initializeActions(): void {
    const actions = [];
    const action = {
      function: () => {
        this.initializeCreateAssignment();
      },
      showAction: true,
      title: 'Ανάθεση Αίτησης σε χρήστη',
      icon: 'assignment',
      color: 'black'
    };
    actions.push(action);
    if (actions.length > 0) {
      this.basicDetailsConfig.actions = actions;
      this.basicDetailsConfig.showActions = true;
    } else {
      this.basicDetailsConfig.showActions = false;
    }
  }

  private async initializeCreateAssignment() {
    this.loadingService.show();
    const users = await this.commonService.getListWithParameters('user', 'role_id=14');
    this.loadingService.hide();
    this.dialog.open(AssignRequestComponent, {
      data: {
        users,
        requestId: this.returnDetails.requestId
      }
    });
  }

  public getRecord(row): void {
    this.dialog.open(AssignAndDetailModalComponent, {
      data : {
        orderId: this.returnDetails.requestId,
        refrigeratorId: row.id,
        type: 0,
        serial_number: row.serial_number
      }
    });
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;

    const numRows = this.dataSource.length;

    return numSelected === numRows;
  }

  public confirmCompletion(): void {
    this.dialog.open(ConfirmActionDialogComponent, {
      data : {
        confirmFunction: this.completeCommand,
        message: 'Είστε σίγουροι ότι θέλετε να θέσετε την εντολή ως ολοκληρωμένη?',
        title: 'Ολοκλήρωση Εντολής Επιστροφής',
        itemToExecute: {returnId: this.returnId}
      }
    });
  }


  public async completeCommand(object) {
    try {
      await this.purchaseOrdersService.updateReceiptCommand(`return_command/web/completion/${object.returnId}`, {});
      this.dialog.open(ActionCompletionDialogComponent, {
        data: {
          buttonText: 'Πλοήγηση στις επιστροφές ψυγείων',
          redirectURL: `returns`
        }
      });
    } catch (e) {
      console.log(e);
    }
  }


  public async confirmDisapproval() {
    const items = [];
    this.selection.selected.forEach(selectedItem => {
      const model = {
        id: selectedItem.id,
      };
      items.push(model);
    });
    const message = items.length > 0 ? 'Ειστε σίγουροι ότι θέλετε να εγκρίνετε τα επιλεγμένα ψυγεία?' : 'Ειστε σιγουροι ότι θέλετε να ακυρώσετε ολόκληρη την εντολή?';
    const title = items.length > 0 ? 'Εγκριση ψυγειων' : 'Ακύρωση εντολής';
    this.dialog.open(ConfirmActionDialogComponent, {
      data : {
        confirmFunction: this.disapproveRequest,
         message,
         title,
        itemToExecute: {returnId: this.returnId, items}
      }
    });
  }

  public async disapproveRequest(object: any) {
    try {
       await this.purchaseOrdersService.updateReceiptCommand(`return_command/web/approval/${object.returnId}`, object.items);
       this.dialog.open(ActionCompletionDialogComponent, {
        data: {
          buttonText: 'Πλοήγηση στις επιστροφές ψυγείων',
          redirectURL: `returns`
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  public isInventoryValid(): boolean {
    return !(this.selection.selected.length > 0);
  }

  public masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.forEach((row) => this.selection.select(row));
  }

}
