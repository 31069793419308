import { Component, OnInit } from '@angular/core';
import OrderDetailsConfig from '../../table/configs/receiving-order-details-serials.json';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-receiving-order-details-info',
  templateUrl: './receiving-order-details-info.component.html',
  styleUrls: ['./receiving-order-details-info.component.scss']
})
export class ReceivingOrderDetailsInfoComponent implements OnInit {
  public orderDetailsConfig: any;
  public orderDetailsSerials: any;
  constructor(public route: ActivatedRoute) {
    this.orderDetailsSerials =  JSON.parse(localStorage.getItem('orderDetailsSerials'));
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.orderDetailsConfig = OrderDetailsConfig;
      this.orderDetailsConfig.filter = `command_details_id=${params.id}`;
    });
  }

}
