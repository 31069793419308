import {ActionCompletionDialogComponent} from './common/action-completion-dialog/action-completion-dialog.component';
import {AppComponent} from './app.component';
import {AppErrorHandler} from './common/app-error-handler';
import {AppRoutingModule} from './app-routing.module';
import {AuthInterceptor} from './interceptors/auth.service';
import {BasicDetailsComponent} from './details/basic-details/basic-details.component';
import {BasicInfoComponent} from './common/basic-info/basic-info.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ConfirmActionDialogComponent} from './common/confirm-action-dialog/confirm-action-dialog.component';
import {CookieService} from 'ngx-cookie-service';
import {CreateInventoryDialogComponent} from './users/user-details/create-inventory-dialog/create-inventory-dialog.component';
import {DetailsDialogComponent} from './common/details-dialog/details-dialog.component';
import {EditUserDialogComponent} from './users/user-details/edit-user-dialog/edit-user-dialog.component';
import {environment} from '../environments/environment';
import {ErrorComponent} from './error/error.component';
import {ErrorDialogComponent} from './common/error-dialog/error-dialog.component';
import {ErrorHandler, NgModule} from '@angular/core';
import {EventLogsComponent} from './common/event-logs/event-logs.component';
import {FilterTableDialogComponent} from './table/filter-table-dialog/filter-table-dialog.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HashLocationStrategy, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HelpComponent} from './help/help.component';
import {HomeComponent} from './home/home.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InventoryDetailsComponent} from './inventory-details/inventory-details.component';
import {InventoryRefrigeratorsDialogComponent} from './inventory-details/inventory-refrigerators-dialog/inventory-refrigerators-dialog.component';
import {LayoutComponent} from './layout/layout.component';
import {LoadingComponent} from './common/ngx-loading/ngx-loading.component';
import {LoginComponent} from './login/login.component';
import {LogoutDialogComponent} from './logout-dialog/logout-dialog.component';
import {MaterialModule} from './material/material.module';
import {NgxMaskModule} from 'ngx-mask';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {RefrigeratorDetailsComponent} from './refrigerator-details/refrigerator-details.component';
import {SalePointDetailsComponent} from './sale-point-details/sale-point-details.component';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {TableComponent} from './table/table.component';
import {UserBasicComponent} from './users/user-basic/user-basic.component';
import {UserDetailsComponent} from './users/user-details/user-details.component';
import {UserFormComponent} from './users/user-form/user-form.component';
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { CreateLoadingDialogComponent } from './users/user-details/create-loading-dialog/create-loading-dialog.component';
import { CreateReceiptDialogComponent } from './users/user-details/create-receipt-dialog/create-receipt-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import { LoadingOrdersComponent } from './loading-orders/loading-orders.component';
import { ReceiptOrdersComponent } from './receipt-orders/receipt-orders.component';
import {MatTree} from '@angular/material';
import { OrderDetailsComponent } from './purchase-orders/order-details/order-details.component';
import { LoadingOrderDetailsComponent } from './loading-orders/loading-order-details/loading-order-details.component';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { LoadingOrderDetailsInfoComponent } from './loading-orders/loading-order-details-info/loading-order-details-info.component';
import { ReceivingOrderDetailsComponent } from './receipt-orders/receiving-order-details/receiving-order-details.component';
import { ReceivingOrderDetailsInfoComponent } from './receipt-orders/receiving-order-details-info/receiving-order-details-info.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { RequestOrderDetailsComponent } from './request-order-details/request-order-details.component';
import { ReturnRefrigeratorDetailsComponent } from './return-refrigerator-details/return-refrigerator-details.component';
import { AssignAndDetailModalComponent } from './common/assign-and-detail-modal/assign-and-detail-modal.component';
import { AssignRequestComponent } from './return-refrigerator-details/assign-request/assign-request.component';
import { CreateLoadingRequestComponent } from './common/create-loading-request/create-loading-request.component';
import { CreateUnloadingRequestComponent } from './common/create-unloading-request/create-unloading-request.component';
import { RefrigeratorBudgetComponent } from './refrigerator-budget/refrigerator-budget.component';
import { InitializeSumsDialogComponent } from './refrigerator-budget/initialize-sums-dialog/initialize-sums-dialog.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { HeaderComponent } from './common/header/header.component';

const config: SocketIoConfig = {
  url: environment.socketUrl,
  options: {}
};

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    ActionCompletionDialogComponent,
    AppComponent,
    BasicDetailsComponent,
    BasicInfoComponent,
    ConfirmActionDialogComponent,
    CreateInventoryDialogComponent,
    CreateLoadingDialogComponent,
    CreateReceiptDialogComponent,
    DetailsDialogComponent,
    EditUserDialogComponent,
    ErrorComponent,
    ErrorDialogComponent,
    EventLogsComponent,
    FilterTableDialogComponent,
    HelpComponent,
    HomeComponent,
    InventoryDetailsComponent,
    InventoryRefrigeratorsDialogComponent,
    LayoutComponent,
    LoadingComponent,
    LoginComponent,
    LogoutDialogComponent,
    RefrigeratorDetailsComponent,
    SalePointDetailsComponent,
    TableComponent,
    UserBasicComponent,
    UserDetailsComponent,
    UserFormComponent,
    PurchaseOrdersComponent,
    CreateLoadingDialogComponent,
    CreateReceiptDialogComponent,
    LoadingOrdersComponent,
    ReceiptOrdersComponent,
    OrderDetailsComponent,
    LoadingOrderDetailsComponent,
    LoadingOrderDetailsInfoComponent,
    ReceivingOrderDetailsComponent,
    ReceivingOrderDetailsInfoComponent,
    StatisticsComponent,
    RequestOrderDetailsComponent,
    ReturnRefrigeratorDetailsComponent,
    AssignAndDetailModalComponent,
    AssignRequestComponent,
    CreateLoadingRequestComponent,
    CreateUnloadingRequestComponent,
    RefrigeratorBudgetComponent,
    InitializeSumsDialogComponent,
    MainNavComponent,
    HeaderComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMaskModule.forRoot({}),
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    SocketIoModule.forRoot(config),
    MatSelectInfiniteScrollModule,
    MatFormFieldModule,
    MatSelectModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule
  ],
  entryComponents: [
    CreateLoadingDialogComponent,
    CreateReceiptDialogComponent,
    AssignAndDetailModalComponent,
    AssignRequestComponent,
    CreateLoadingRequestComponent,
    CreateUnloadingRequestComponent,
    InitializeSumsDialogComponent
  ],
  providers: [
    CookieService,
    MatTree,
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    },
    {
      provide: ErrorHandler, useClass: AppErrorHandler
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    }
  ]
})

export class AppModule {
}
