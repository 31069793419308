import { ActionCompletionDialogComponent } from '../../../common/action-completion-dialog/action-completion-dialog.component';
import { CommonService } from '../../../services/common.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DetailsDialogComponent } from '../../../common/details-dialog/details-dialog.component';
import { LoadingService } from '../../../services/loading.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as moment from 'moment';

@Component({
  templateUrl: './create-inventory-dialog.component.html',
  styleUrls: ['./create-inventory-dialog.component.scss']
})

export class CreateInventoryDialogComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[];
  public selection: SelectionModel<any>;

  constructor(private commonService: CommonService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<CreateInventoryDialogComponent>,
              private loadingService: LoadingService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dataSource = new MatTableDataSource<any>(this.data.salePoints);

    this.displayedColumns = ['select', 'customer_name', 'vat_number', 'city', 'address', 'phone_number1',
      'type_name', 'actions'];

    this.selection = new SelectionModel<any>(true, []);

    this.dialogRef.updateSize('500');
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.commonService.setPaginatorTexts(this.paginator);

    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;

    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  public isInventoryValid(): boolean {
    return !(this.selection.selected.length > 0);
  }

  public masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  public openDetailsDialog(element: any, type: string): void {
    this.dialog.open(DetailsDialogComponent, {
      data: {
        object: element,
        type
      }
    });
  }

  public submitInventory(): void {
    this.loadingService.show();

    this.dialogRef.close();

    const inventory = {
      date_created: moment().format('YYYY-MM-DD'),
      sale_points: this.selection.selected.map((salePoint) => salePoint.id),
      user: {
        id: this.data.user.id,
        role_alternate_name: this.data.user.role_alternate_name
      }
    };

    this.commonService.insertObject('inventory/web', inventory).then((response) => {
      this.dialog.open(ActionCompletionDialogComponent, {
        data: {
          buttonText: 'Πλοήγηση στην απογραφή',
          redirectURL: `inventory/${response.id}`
        }
      });

      this.loadingService.hide();
    });
  }
}
