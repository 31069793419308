import {Component, Input} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss']
})

export class BasicDetailsComponent {
  @Input() config: any;
  constructor() {
  }

  public setBasicDataCardClass(index: number): string {
    switch (index) {
      case 0:
        return 'card first-card';
      case 1:
        return 'card second-card';
      case 2:
        return 'card third-card';
    }
  }

  public setCardWidth(): number {
    switch (this.config.infoRow.length) {
      case 1:
        return 100;
      case 2:
        return 49;
      case 3:
        return 32;
    }
  }

  public setStatusClass(): string {
    return this.config.status_value === 0 ? 'status danger-status' : 'status success-status';
  }

  public setSubtitleMessage(element: any, index: number): string {
    return `${element.value}${index !== this.config.subtitle.length - 1 ? ' / ' : ''}`;
  }

  public showLinkElement(element: any): boolean {
    return element.type && element.type === 'link';
  }
}
