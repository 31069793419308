import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Socket} from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})

export class InventoriesService {
  public insertedInventories: Observable<any>;
  private serviceModel: string;

  constructor(private httpClient: HttpClient,
              private socket: Socket) {
    this.insertedInventories = this.socket.fromEvent<any>('insertInventory');

    this.serviceModel = 'inventory';
  }

  public getInventory(id: number): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(`${this.serviceModel}?first=true&filter=id=${id}`).subscribe((result) => {
        resolve(result);
      });
    });
  }
}
