import { Component, OnInit } from '@angular/core';
import PurchaseOrdersConfig from '../table/configs/purchaseOrders.json';
@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.scss']
})
export class PurchaseOrdersComponent implements OnInit {
  public purchaseOrdersConfig: any;
  constructor() {
    this.purchaseOrdersConfig = PurchaseOrdersConfig;
  }

  ngOnInit() {
  }

}
