import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Component, OnInit} from '@angular/core';
import {Details} from '../details/details';
import {EventLogsService} from '../services/event-logs.service';
import {LoadingService} from '../services/loading.service';
import {MatTabGroup} from '@angular/material/tabs';
import {SalePointsService} from '../services/sale-points.service';

import InventoriesConfig from './tables-configs/inventories.json';
import RefrigeratorsConfig from './tables-configs/refrigerators.json';

@Component({
  templateUrl: './sale-point-details.component.html',
  styleUrls: ['./sale-point-details.component.scss']
})

export class SalePointDetailsComponent extends Details implements OnInit {
  public basicDetailsConfig: any;
  public eventLogs: any[];
  public initializePage: boolean;
  public inventoriesConfig: any;
  public mainDataSections: any[];
  public refrigeratorsConfig: any;
  private salePoint: any;

  constructor(private authService: AuthService,
              private eventLogsService: EventLogsService,
              private loadingService: LoadingService,
              private route: ActivatedRoute,
              private salePointsService: SalePointsService) {
    super();

    this.inventoriesConfig = InventoriesConfig;

    this.refrigeratorsConfig = RefrigeratorsConfig;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let promises: Array<Promise<any>>;

      promises = [];

      const promise1 = this.salePointsService.getSalePoint(+params.get('id')).then((salePoint) => {
        this.salePoint = salePoint;
      });

      promises.push(promise1);

      if (this.showEventLogs()) {
        const promise2 = this.eventLogsService.getEventLogs('sale-point', +params.get('id')).then((eventLogs) => {
          this.eventLogs = eventLogs;
        });

        promises.push(promise2);
      }

      Promise.all(promises).then(() => {
        this.inventoriesConfig.filter = 'sale_point_id = ' + this.salePoint.id;

        this.refrigeratorsConfig.filter = 'sale_point_id = ' + this.salePoint.id;

        this.basicDetailsConfig = {
          showActions: this.showActions(),
          status_name: 'Ενεργό',
          status_value: 1,
          subtitle: [
            {
              title: 'Α.Φ.Μ.',
              value: this.salePoint.vat_number
            }
          ],
          title: `Πληροφορίες Σημείου Πώλησης: ${this.salePoint.customer_name}`
        };

        this.mainDataSections = this.salePointsService.initializeSalePoint(this.salePoint);

        this.initializePage = true;

        this.loadingService.hide();
      });
    });
  }

  public changeTab(tabs: MatTabGroup): void {
    if (tabs.selectedIndex !== 0) {
      this.loadingService.show();
    }
  }

  public setCardWidth(): number {
    return this.showEventLogs() ? 66 : 100;
  }

  protected showActions(): boolean {
    return false;
  }

  public showEventLogs(): boolean {
    return this.authService.isUserBelongsTo('basicUser') ||
      this.authService.isUserBelongsTo('transportManager');
  }
}
