import {FormControl} from '@angular/forms';
import {ReplaySubject} from 'rxjs';

export interface Column {
  customSelect?: CustomSelectOption[];
  customSelected?: CustomSelectOption[];
  filteredSelect: ReplaySubject<any[]>;
  fromValue: string;
  name: string;
  select: SelectConfig;
  selected: SelectOption[];
  selectFilterControl: FormControl;
  selectPlaceholder: string;
  sort: boolean;
  text: string;
  title: string;
  toValue: string;
  type: Types;
}

interface CustomSelectOption {
  filter: string;
  id: number;
  name: any;
}

interface SelectConfig {
  model: string;
  options: any;
  reference: string;
}

interface SelectOption {
  id: number;
  name: string;
}

export class TableConfig {
  public columns: Column[];
  public filter: string;
  public insertButtonText: string;
  public insertLink: string;
  public insertRole: string;
  public model: string;
  public navigatePath: string;
  public navigateProperty: string;
  public nested: boolean;
  public orderColumn: string;
  public orderDirection: string;
  public searchFields: string;
  public showRefrigerators: boolean;
  public title: string;
  public allowExport: boolean;
}

export enum Types {
  customSelect = 'customSelect',
  date = 'date',
  dateTime = 'dateTime',
  multipleSelect = 'multipleSelect',
  number = 'number',
  select = 'select',
  text = 'text'
}
