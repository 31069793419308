import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {ErrorHandler, Injectable} from '@angular/core';
import {LoadingService} from '../services/loading.service';
import {MatDialog} from '@angular/material';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private dialog: MatDialog,
              private loadingService: LoadingService) {
  }

  handleError(error) {
    console.log(error);

    this.loadingService.hide();

    this.dialog.open(ErrorDialogComponent, {
      data: {
        error,
        mode: 'global'
      }
    });
  }
}
