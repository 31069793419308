import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {Router} from '@angular/router';

@Component({
  templateUrl: './action-completion-dialog.component.html',
  styleUrls: ['./action-completion-dialog.component.scss']
})

export class ActionCompletionDialogComponent implements OnInit {
  public buttonText: string;
  public redirectURL: string;
  public reset: boolean;

  constructor(public dialogRef: MatDialogRef<ActionCompletionDialogComponent>, private allDialogs: MatDialog,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.buttonText = this.data.buttonText;

    this.redirectURL = this.data.redirectURL;

    this.reset = this.data.reset;
  }

  ngOnInit() {
    this.dialogRef.updateSize('600px');

    this.dialogRef.disableClose = true;
  }

  public closeDialog(redirectURL?: string): void {
    this.dialogRef.close();
    this.allDialogs.closeAll();
    if (redirectURL) {
      this.router.navigate([this.redirectURL]);
    } else if (this.reset) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigated = false;

      window.scrollTo(0, 0);

      this.router.navigate([this.router.url]);
    }
  }

  public showRedirectButton(): boolean {
    return this.redirectURL !== undefined;
  }
}
