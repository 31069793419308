import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Form, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSelect, MatStepper, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {PurchaseOrdersService} from '../../../services/purchase-orders.service';
import {IOrderDetails, ISalePoint} from '../../../models/models';
import * as moment from 'moment';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {ActionCompletionDialogComponent} from '../../../common/action-completion-dialog/action-completion-dialog.component';
import {take, takeUntil} from 'rxjs/operators';


@Component({
  templateUrl: './create-loading-dialog.component.html',
  styleUrls: ['./create-loading-dialog.component.scss']
})
export class CreateLoadingDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public firstFormGroup: FormGroup;
  public orders: any;
  public ordersDataSource: any;
  public poDetailsDataSource = new MatTableDataSource<IOrderDetails>();
  public displayedColumns: string[] = ['select', 'material_id', 'material_description', 'quantity_ordered', 'loaded_quantity', 'loading_quantity'];
  public selection = new SelectionModel<IOrderDetails>(true, []);
  private user: any;
  public minDate: Date;
  public deliveryPointsRecipients: any[];
  public deliveryPoints: any[];
  public deliveryPointsDataSource: ISalePoint[];
  private selectedOrderId: number;
  public loading: boolean;
  public selectedOrderRequest: any;
  public selectedSalePoints: ISalePoint[] = [];
  public total = 100;
  public limit = 10;
  public offset = 0;
  public options = new BehaviorSubject<any>({refresh: true, data: []});
  public options$: ISalePoint[];
  private readonly type: number;
  public deliveryPointCtrl: FormControl = new FormControl();
  public deliveryPointFilterCtrl: FormControl = new FormControl();
  public filteredDeliveryPoints: ReplaySubject<ISalePoint[]> = new ReplaySubject<ISalePoint[]>(1);
  protected _onDestroy = new Subject<void>();
  private isSearchCompleted = false;
  public selectedSalePointValue: any;
  private searchString: string;
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  @ViewChild('singleSelect', {static: true}) singleSelect: MatSelect;

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog,
              private purchaseOrdersService: PurchaseOrdersService,
              private dialogRef: MatDialogRef<CreateLoadingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.type = this.data.type;
    this.loading = false;
    this.orders = this.data.orders;

    this.options$ = [];
    this.ordersDataSource = [];

    this.user = this.data.user;

    this.minDate = new Date();
    this.deliveryPointsDataSource = [];
    this.deliveryPointsRecipients = [];
    this.deliveryPoints = [];


    this.firstFormGroup = new FormGroup({
      orders: new FormControl({value: '', disable: false}, Validators.required),
      delivery_date: new FormControl({value: '', disable: false}, Validators.required),
      delivery_point_recipient: new FormControl({value: '', disable: false}, Validators.required),
      delivery_point: new FormControl({value: '', disable: false}),
    });
  }

  public selectedSalePointChanged(): void {
    this.showLoading(true);
    this.isSearchCompleted = true;
    this.deliveryPointFilterCtrl.setValue(this.searchString);
    const selectedSalePoint = this.deliveryPointCtrl.value;
    // tslint:disable-next-line:max-line-length
    this.purchaseOrdersService.getList('request/web', `sale_point_id=${selectedSalePoint.id} AND ( status_id = 6 OR status_id = 7 ) `).then((data: any) => {
      this.orders = data;
      this.showLoading(false);
    });
  }

  ngAfterViewInit() {
    // this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredDeliveryPoints
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: ISalePoint, b: ISalePoint) => a && b && a.id === b.id;
      });
  }

  public order_request_changed() {
    this.showLoading(true);
    const selectedOrder = this.firstFormGroup.get('orders').value;
    this.selectedOrderId = selectedOrder.id;
    this.firstFormGroup.controls.delivery_date.setValue(new Date(selectedOrder.desired_delivery_date));
    this.showLoading(false);
  }

  protected filterDeliveryPoints() {
    if (!this.deliveryPointsDataSource) {
      return;
    }

    // get the search keyword
    let search = this.deliveryPointFilterCtrl.value;
    this.searchString = search;
    if (!search) {
      this.filteredDeliveryPoints.next(this.deliveryPointsDataSource.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDeliveryPoints.next(
      this.deliveryPointsDataSource.filter(salePoint => salePoint.customer_name.toLowerCase().indexOf(search) > -1)
    );
  }

  getNextBatch() {
    this.showLoading(true);
    const result: ISalePoint[] = this.deliveryPointsDataSource.slice(this.offset, this.offset + this.limit);

    if (this.offset === 0) {
      this.options.next({refresh: true, data: result});
    } else {
      this.options.next({refresh: false, data: result});
    }
    this.offset += this.limit;
    this.showLoading(false);
  }

  ngOnInit() {

    this.showLoading(true);
    this.purchaseOrdersService.getList('sale_point_category').then(data => {
      if (this.type === 1) {
        this.deliveryPointsRecipients = data.filter(recipient => recipient.id !== 1 && recipient.id !== 5);
      } else {
        this.deliveryPointsRecipients = data.filter(recipient => recipient.id === 1 || recipient.id === 4);
        // this.deliveryPointsRecipients = data.filter(recipient => recipient.id === 4);
      }
      this.showLoading(false);
    });

    this.deliveryPointFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterDeliveryPoints();
      });
    // this.options.asObservable().subscribe((dataNext: any) => {
    //
    //   if (dataNext.refresh === true) {
    //     this.options$ = dataNext.data;
    //   } else {
    //     this.options$ = [...this.options$, ...dataNext.data];
    //   }
    // });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public showLoading(action: boolean) {
    this.loading = action;
    if (action) {
      this.firstFormGroup.disable();
    } else {
      this.firstFormGroup.enable();
    }
  }

// Απο αίτηση
  public deliveryPointSelected(): void {
    const selectedRecipient = this.firstFormGroup.get('delivery_point_recipient').value;
    this.isSearchCompleted = false;
    if (selectedRecipient) {
      this.deliveryPointsDataSource = [];
      this.firstFormGroup.controls.delivery_point.reset();
      this.showLoading(true);
      this.deliveryPointCtrl.disable();
      const filter = selectedRecipient.id === 1 ? `(account_group_id <> 3000 AND account_group_id <> 3009) AND (sales_organization_id = 1000 OR sales_organization_id = 2000)` : `category_id=${selectedRecipient.id}`;
      this.purchaseOrdersService.getList('sale_point', filter).then((data: ISalePoint[]) => {
        // data.sort((a, b) => (a.customer_name > b.customer_name) ? 1 : ((b.customer_name > a.customer_name) ? -1 : 0));

        this.deliveryPointsDataSource = data;
        this.deliveryPointCtrl.setValue(this.deliveryPointsDataSource[-1]);
        this.filteredDeliveryPoints.next(this.deliveryPointsDataSource.slice());
        // this.offset = 0;
        // this.getNextBatch();

        this.showLoading(false);
        this.deliveryPointCtrl.enable();
      });
    } else {
      this.ordersDataSource = [];
    }
  }

  public previous() {
    if (this.selection.selected.length > 0 ) {
      this.selection.clear();
    }
    this.stepper.previous(); // = this.stepper.selectedIndex > 0  ?  this.stepper.selectedIndex-- : 0;
  }

  public next() {
    if (this.stepper.selectedIndex === 0) {
      if (this.type === 1) {
        this.showLoading(true);
        const selectedOrder = this.firstFormGroup.get('orders').value;
        this.selectedOrderId = selectedOrder.id;
        this.purchaseOrdersService.getList('po_details', `po_id=${selectedOrder.id}`).then(data => {
          data.forEach(d => {
            d.loaded_quantity = d.loaded_quantity === null ? 0 : d.loaded_quantity;
          });
          this.poDetailsDataSource.data = data;
          this.showLoading(false);
        });
      } else {
        const selectedOrder = this.firstFormGroup.get('orders').value;
        this.purchaseOrdersService.getList('request/web/details', `request_id=${selectedOrder.id} AND (sales_inspector_action_id <> 2 OR sales_inspector_action_id is null)`).then((data: any) => {
          data.forEach(d => {
            d.loaded_quantity = d.loaded_quantity === null ? 0 : d.loaded_quantity;
          });
          data.forEach(d => {
            d.quantity_ordered = d.quantity;
          });
          this.poDetailsDataSource.data = data;
          this.showLoading(false);
        });
      }
    }
    this.stepper.next(); // = this.stepper.selectedIndex < 3 ? this.stepper.selectedIndex++ : 2;
  }

  public updateData(index: number, event: any): void {
    const value = event.target.value;
    if (index !== -1) {
      this.poDetailsDataSource.data[index].loading_quantity = value ? value : '';
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.poDetailsDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.poDetailsDataSource.data.forEach(row => this.selection.select(row));
  }

  public isLoadingValid(): boolean {
    let valid = true;
    this.selection.selected.forEach(selectedItem => {
      const quantity =  selectedItem.loading_quantity;
      if (quantity === null || !quantity) {
        valid = false;
      }
    });
    // return valid && this.selection.selected.length > 0;
    if (valid && this.selection.selected.length > 0) {
      return false;
    } else {
      return true;
    }
    return !(this.selection.selected.length > 0);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: IOrderDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  public async submitLoading() {

    this.showLoading(true);
    const loadingModel = {
      user_id: this.user.id,
      order_id: this.selectedOrderId,
      order_type_id: this.type === 1 ? 1 : 2,
      sale_point_id: this.selectedSalePointValue.id,
      desired_delivery_date: moment(this.firstFormGroup.get('delivery_date').value).format('YYYY-MM-DD'),
      command_type: 0,
      command_id: null,
      materials: []
    };


    this.selection.selected.forEach(selectedItem => {
      const model = {
        material_id: selectedItem.material_id,
        total_quantity: selectedItem.loading_quantity
      };
      loadingModel.materials.push(model);
    });

    try {
      await this.purchaseOrdersService.storeLoadingCommand('loading_command', loadingModel);
      this.dialogRef.close();

      this.dialog.open(ActionCompletionDialogComponent, {
        data: {
          buttonText: 'Πλοήγηση στις εντολές φόρτωσης',
          redirectURL: `loading_orders`
        }
      });
    } catch (e) {
      this.dialogRef.close();
    }
  }

  public showOptions(): boolean {
    if (this.deliveryPointFilterCtrl.value) {
      return this.deliveryPointFilterCtrl.value.toString().length >= 2;
    } else {
      return false;
    }
  }

  public selectedSalePoint() {
    this.isSearchCompleted = true;
    this.deliveryPointFilterCtrl.setValue(this.searchString);
    const selectedSalePoint = this.deliveryPointCtrl.value;
  }
}
