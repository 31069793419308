import {CommonService} from './common.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Socket} from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})

export class EventLogsService {
  private serviceModel: string;
  public updatedEventLogs: Observable<any>;

  constructor(private commonService: CommonService,
              private httpClient: HttpClient,
              private socket: Socket) {
    this.updatedEventLogs = this.socket.fromEvent<any>('updateEventLog');

    this.serviceModel = 'event_log';
  }

  public getEventLogs(model: string, modelID: number): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(`${this.serviceModel}?filter=model = '${model}' AND model_id = ${modelID}`)
        .subscribe((eventLogs: any[]) => {
          if (eventLogs.length > 0) {
            this.commonService.updateObject('event_log', eventLogs).then(() => {
              resolve(eventLogs);
            });
          } else {
            resolve(eventLogs);
          }
        });
    });
  }

  public getEventLogsCount(): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(`${this.serviceModel}?filter=is_displayed_value = 0&select=COUNT(*) AS count`)
        .subscribe((result) => {
          resolve(result);
        });
    });
  }
}
