import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {PurchaseOrdersService} from '../../services/purchase-orders.service';
import {InitializeSumsDialogComponent} from '../../refrigerator-budget/initialize-sums-dialog/initialize-sums-dialog.component';
import {CommonService} from '../../services/common.service';

@Component({
  templateUrl: './confirm-action-dialog.component.html',
  styleUrls: ['./confirm-action-dialog.component.scss']
})

export class ConfirmActionDialogComponent implements OnInit {
  // tslint:disable-next-line:ban-types
  public confirmFunction: Function;
  public message: string;
  public title: string;
  public itemToExecute: any;

  constructor(private dialog: MatDialog,
              public dialogReference: MatDialogRef<InitializeSumsDialogComponent>,
              public commonService: CommonService,
              public purchaseOrdersService: PurchaseOrdersService,
              private dialogRef: MatDialogRef<ConfirmActionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.confirmFunction = this.data.confirmFunction;
    this.itemToExecute = this.data.itemToExecute;
    this.message = this.data.message;

    this.title = this.data.title;
  }

  ngOnInit() {
    this.dialogRef.updateSize('600px');
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public confirmAction(): void {
    this.dialogRef.close();

    this.confirmFunction(this.itemToExecute);
  }
}
