import { Component, OnInit } from '@angular/core';
import {LoadingService} from '../services/loading.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
public reportUrl: string;
  constructor(public loadingService: LoadingService) {
    this.loadingService.hide();
    this.reportUrl = 'https://datastudio.google.com/reporting/ef105f62-3897-499e-982b-2d4692cdc941';
  }

  ngOnInit() {
  }

}
