import { AuthService } from './auth.service';
import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrdersGuardService implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) {
  }
  canActivate() {
    return this.authService.getUser().then((user) => {
      if (user.role_alternate_name === 'basicUser' ||
        user.role_alternate_name === 'transportManager' ||
        user.role_alternate_name === 'technicalManager') {
        return true;
      }

      this.router.navigate(['access-denied']);

      return false;
    });
  }
}
