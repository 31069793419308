import { Component, OnInit } from '@angular/core';
import OrderDetailsConfig from '../../table/configs/receiving-order-details.json';
import {ActivatedRoute} from '@angular/router';
import {PurchaseOrdersService} from '../../services/purchase-orders.service';
import {ActionCompletionDialogComponent} from '../../common/action-completion-dialog/action-completion-dialog.component';
import {MatDialog} from '@angular/material';
@Component({
  selector: 'app-receiving-order-details',
  templateUrl: './receiving-order-details.component.html',
  styleUrls: ['./receiving-order-details.component.scss']
})
export class ReceivingOrderDetailsComponent implements OnInit {
  public orderDetails: any;
  public orderDetailsConfig: any;
  constructor(public route: ActivatedRoute, public dialog: MatDialog, public purchaseOrdersService: PurchaseOrdersService) {
    this.orderDetails = JSON.parse(localStorage.getItem('orderDetails'));
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.orderDetailsConfig = OrderDetailsConfig;
      this.orderDetailsConfig.filter = `command_id=${params.id}`;

      console.log(this.orderDetails);
    });

    console.log(this.orderDetails);
  }

  public async completeReceiptCommand(): Promise<void> {
    try {
      await this.purchaseOrdersService.updateReceiptCommand('unloading_command/details/complete', {id: this.orderDetails.order_id, order_type_id: this.orderDetails.order_type_id, order_id:  this.orderDetails.order_id1});
      this.dialog.open(ActionCompletionDialogComponent, {
        data: {
          buttonText: 'Πλοήγηση στις παραλαβές',
          redirectURL: `receipt_orders`
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

}
