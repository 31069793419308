import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public user: any;

  constructor(private cookieService: CookieService,
              private httpClient: HttpClient,
              private router: Router) {
  }

  public getUser(): Promise<any> {
    return new Promise((resolve) => {
      if (this.user) {
        return resolve(this.user);
      }

      this.httpClient.get('user/me').subscribe((user) => {
        this.user = user;

        resolve(user);
      }, () => {
        this.cookieService.delete('Token');

        this.router.navigate(['login']);
      });
    });
  }

  public isUserBelongsTo(roleName: string): boolean {
    return this.user.role_alternate_name === roleName;
  }

  public logout(): void {
    this.user = null;

    this.cookieService.delete('Token');

    this.router.navigate(['/login']);
  }
}
