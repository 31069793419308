import {Component, OnInit} from '@angular/core';
import OrderDetailsConfig from '../../table/configs/loading_order_details.json';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {CommonService} from '../../services/common.service';
import {LoadingService} from '../../services/loading.service';
import {MatDialog} from '@angular/material';
import {CreateLoadingRequestComponent} from '../../common/create-loading-request/create-loading-request.component';
import {CreateUnloadingRequestComponent} from '../../common/create-unloading-request/create-unloading-request.component';

@Component({
  selector: 'app-loading-order-details',
  templateUrl: './loading-order-details.component.html',
  styleUrls: ['./loading-order-details.component.scss']
})
export class LoadingOrderDetailsComponent implements OnInit {
  public orderDetails: any;
  public orderDetailsConfig: any;

  public basicDetailsConfig: any;
  public showBasicInfo: boolean;
  private user: any;

  constructor(public route: ActivatedRoute, private authService: AuthService, private commonService: CommonService,
              public loadingService: LoadingService,
              private dialog: MatDialog) {
    this.orderDetails = JSON.parse(localStorage.getItem('orderDetails'));

    this.showBasicInfo = false;
    this.initUser();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.orderDetailsConfig = OrderDetailsConfig;
      this.orderDetailsConfig.filter = `command_id=${params.id}`;
    });
  }

  private async initUser() {
    this.user = await this.authService.getUser();
    this.initBasicInfo();
  }

  private async initBasicInfo() {
    this.showBasicInfo = true;
    this.basicDetailsConfig = {
      showActions: await this.commonService.showActions(this.user),
      subtitle: [
        {
          title: 'Αριθμός Εντολής',
          value: this.orderDetails.order_id
        }
      ],
      title: 'Πληροφορίες Εντολής Φόρτωσης'
    };

    this.initializeActions();
  }

  private initializeActions(): void {
    const actions = [];
    const action = {
      function: () => {
        this.initializeCreateUnloadingOrderDialog(this.orderDetails.order_type_id);
      },
      showAction: true,
      title: 'Δημιουργία εντολής Παραλαβής',
      icon: 'cloud_upload',
      color: '#266ccd'
    };
    actions.push(action);

    if (actions.length > 0) {
      this.basicDetailsConfig.actions = actions;
      this.basicDetailsConfig.showActions = true;
    } else {
      this.basicDetailsConfig.showActions = false;
    }
  }


  private async initializeCreateUnloadingOrderDialog(type: number) {
    this.loadingService.show();
    const users = await this.commonService.getListWithParameters('user', 'role_id=2 OR role_id=13 OR role_id=14');
    const details = null;
    // await this.commonService.getListWithParameters('request/web/details', `request_id=${this.requestDetails.requestId}`);
    const roles = await this.commonService.getListWithParameters('role', 'id=6 OR id=13 OR id=14');
    this.loadingService.hide();
    this.dialog.open(CreateUnloadingRequestComponent, {
      data: {
        orderType: 2,
        roles,
        users,
        details,
        type,
        order_id: this.orderDetails.order_id,
        purchaseDocument: null
      }
    });
  }
}
