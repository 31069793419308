import {Component} from '@angular/core';
import {LoadingService} from '../services/loading.service';

@Component({
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})

export class HelpComponent {
  public sections: any[];

  constructor(private loadingService: LoadingService) {
    this.sections = [
      {
        questions: [
          {
            steps: [
              'Πλοηγούμαστε στους Χρήστες.',
              'Κάνουμε κλικ στο κουμπί Εισαγωγή Χρήστη.',
              'Στη φόρμα που μας εμφανίζεται πρέπει να συμπληρώσουμε όλα τα στοιχεία του χρήστη.',
              'Πατάμε το κουμπί Καταχώρηση.'
            ],
            title: 'Πώς μπορώ να εισάγω χρήστη στο σύστημα;'
          },
          {
            steps: [
              'Πλοηγούμαστε στο χρήστη που θέλουμε να επεξεργαστούμε.',
              'Κάνουμε κλικ στο κουμπί Επιλέξτε ενέργεια.',
              'Κάνουμε κλικ στην επιλογή Επεξεργασία Στοιχείων.',
              'Στο παράθυρο που εμφανίζεται εκτελούμε τα βήματα που ακουθήσαμε στην Εισαγωγή Χρήστη.',
              'Για να ολοκληρώσουμε την επεξεργασία πατάμε το κουμπί Καταχώρηση.'
            ],
            title: 'Πώς μπορώ να επεξεργαστώ ένα χρήστη;'
          },
          {
            requirements: [
              'Την ενέργεια αυτή μπορούμε να την εκτελέσουμε μόνο για τους ρόλους Οδηγός, Διανομέας Ιδίου Δικτύου, ' +
              'Αντιπρόσωπος Ιδίου Δικτύου και Αντιπρόσωπος Επαρχίας.'
            ],
            steps: [
              'Πλοηγούμαστε στο χρήστη για τον οποίο θέλουμε να δημιουργήσουμε απογραφή.',
              'Κάνουμε κλικ στο κουμπί Επιλέξτε ενέργεια.',
              'Κάνουμε κλικ στην επιλογή Δημιουργία Απογραφής.',
              'Στην περίπτωση που ο χρήστης δε συνδέεται με κάποια σημεία πώλησης, θα εμφανιστεί ένα παράθυρο που θα ' +
              'μας ρωτάει εάν επιθυμούμε να δημιουργήσουμε μια απογραφή για το συγκεκριμένο χρήστη. Σε αντίθετη ' +
              'περίπτωση, θα εμφανιστεί παράθυρο με τα σημεία πώλησης του χρήστη.',
              'Στο παράθυρο που εμφανίζεται επιλέγουμε τα σημεία πώλησης για τα οποία θέλουμε να γίνει απογραφή.',
              'Για να ολοκληρώσουμε την επεξεργασία πατάμε το κουμπί Καταχώρηση.'
            ],
            title: 'Πώς μπορώ να δημιουργήσω απογραφή;'
          }
        ],
        title: 'Χρήστες'
      }
    ];

    this.loadingService.hide();
  }
}
