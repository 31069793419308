import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  private httpClient: HttpClient;

  constructor(private httpBackend: HttpBackend) {
    this.httpClient = new HttpClient(httpBackend);
  }

  public login(user: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.post(`${environment.apiUrl}login/web`, user, { observe: 'response' }).subscribe((result) => {
        resolve(result);
      }, error => resolve(error));
    });
  }
}
