import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {CommonService} from '../services/common.service';
import {Component, OnInit} from '@angular/core';
import {Details} from '../details/details';
import {EventLogsService} from '../services/event-logs.service';
import {InventoriesService} from '../services/inventories.service';
import {LoadingService} from '../services/loading.service';

import SalePointsConfig from './tables-configs/salePoints.json';

@Component({
  templateUrl: './inventory-details.component.html',
  styleUrls: ['./inventory-details.component.scss']
})

export class InventoryDetailsComponent extends Details implements OnInit {
  public basicDetailsConfig: any;
  public eventLogs: any[];
  public initializePage: boolean;
  private inventory: any;
  public refrigeratorsConfig: any;
  public salePointsConfig: any;

  constructor(private authService: AuthService,
              private commonService: CommonService,
              private eventLogsService: EventLogsService,
              private inventoriesService: InventoriesService,
              private loadingService: LoadingService,
              private route: ActivatedRoute) {
    super();

    this.salePointsConfig = SalePointsConfig;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let promises: Array<Promise<any>>;

      promises = [];

      const promise1 = this.inventoriesService.getInventory(+params.get('id')).then((inventory) => {
        this.inventory = inventory;
      });

      promises.push(promise1);

      if (this.showEventLogs()) {
        const promise2 = this.eventLogsService.getEventLogs('inventory', +params.get('id')).then((eventLogs) => {
          this.eventLogs = eventLogs;
        });

        promises.push(promise2);
      }

      Promise.all(promises).then(() => {
        this.salePointsConfig.filter = 'inventory_id = ' + this.inventory.id;

        this.basicDetailsConfig = {
          showActions: this.showActions(),
          status_name: this.inventory.is_completed_name,
          status_value: this.inventory.is_completed_value,
          subtitle: [
            {
              title: 'Ημερομηνία Δημιουργίας',
              value: this.commonService.formatDate(this.inventory.date_created)
            },
            {
              navigateURL: `#/user/${this.inventory.user_id}`,
              title: 'Υπάλληλος',
              type: 'link',
              value: `${this.inventory.user_name}`
            }
          ],
          title: 'Πληροφορίες Απογραφής'
        };

        this.initializePage = true;

        this.loadingService.hide();
      });
    });
  }

  public setCardWidth(): number {
    return this.showEventLogs() ? 66 : 100;
  }

  protected showActions(): boolean {
    return false;
  }

  public showEventLogs(): boolean {
    return this.authService.isUserBelongsTo('basicUser') ||
      this.authService.isUserBelongsTo('transportManager');
  }
}
