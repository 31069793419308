import { ActionCompletionDialogComponent } from '../../../common/action-completion-dialog/action-completion-dialog.component';
import { CommonService } from '../../../services/common.service';
import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoadingService } from '../../../services/loading.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from '../../../services/users.service';

@Component({
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss']
})

export class EditUserDialogComponent {
  public hasChildren: boolean;
  public roles: any[];
  public salesGroups: any[];
  public userBasicForm: FormGroup;
  private user: any;
  public users: any[];

  constructor(private commonService: CommonService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditUserDialogComponent>,
    private loadingService: LoadingService,
    public usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.hasChildren = this.data.hasChildren;

    this.roles = this.data.roles;

    this.salesGroups = this.data.salesGroups;

    this.user = this.data.user;

    this.userBasicForm = this.usersService.initializeUserBasicForm(this.roles, this.user);

    this.users = this.data.users;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public submitUser(): void {
    this.dialogRef.close();

    const draftUser = this.usersService.submitUser(this.userBasicForm, this.user);

    let uniqueSalesGroups = [];

    if (draftUser.hasOwnProperty('salesGroups')) {
      for (let i = 0; i < draftUser.salesGroups.length; i++) {
        if (uniqueSalesGroups.indexOf(draftUser.salesGroups[i]) === -1) {
          uniqueSalesGroups.push(draftUser.salesGroups[i]);
        }
      }

      draftUser.salesGroups = uniqueSalesGroups;
    }

    this.commonService.updateObject('user', draftUser).then(() => {
      this.loadingService.hide();

      this.dialog.open(ActionCompletionDialogComponent, { data: {} });
    });
  }
}
