import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import UsersConfig from '../../table/configs/users.json';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {CommonService} from '../../services/common.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LoadingService} from '../../services/loading.service';
import {ConfirmActionDialogComponent} from '../../common/confirm-action-dialog/confirm-action-dialog.component';
import {ActionCompletionDialogComponent} from '../../common/action-completion-dialog/action-completion-dialog.component';
import {PurchaseOrdersService} from '../../services/purchase-orders.service';
@Component({
  selector: 'app-assign-request',
  templateUrl: './assign-request.component.html',
  styleUrls: ['./assign-request.component.scss']
})
export class AssignRequestComponent implements OnInit {
public usersConfig: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[];
  public selection: SelectionModel<any>;
  public requestId: string;

  constructor(private commonService: CommonService,
              private purchaseOrdersService: PurchaseOrdersService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<AssignRequestComponent>,
              private loadingService: LoadingService,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.requestId = this.data.requestId;
    this.usersConfig = JSON.parse(JSON.stringify(UsersConfig));

    this.dataSource = new MatTableDataSource<any>(this.data.users);

    this.displayedColumns = ['select', 'name', 'role_name', 'status_name'];

    this.selection = new SelectionModel<any>(true, []);

    this.dialogRef.updateSize('500');
  }

  ngOnInit() {
    this.usersConfig.filter = `role_id = 14`;
    this.dataSource.paginator = this.commonService.setPaginatorTexts(this.paginator);

    this.dataSource.sort = this.sort;
  }
  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;

    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  public masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  isFormValid(): boolean {
    return this.selection.selected.length === 0;
  }

  submitAssignment() {

  }

  public async assignCommandToUser(object: any): Promise<void> {
    try {
      await this.purchaseOrdersService.updateReceiptCommand(`return_command/web/routing/${object.commandId}`,
        {recipient_sale_point_id: object.salePointId});
      this.dialog.open(ActionCompletionDialogComponent, {
        data: {
          buttonText: 'Πλοήγηση στις επιστροφές ψυγείων',
          redirectURL: `returns`
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  public async confirmAssignment(): Promise<any> {
    this.loadingService.show();
    const filter = `user_id=${this.selection.selected[0].id} AND category_id = 2`;
    const salePoint = await this.purchaseOrdersService.getList('sale_point', filter);
    this.loadingService.hide();
    this.dialog.open(ConfirmActionDialogComponent, {
      data: {
        confirmFunction: this.assignCommandToUser,
        message: `Είστε σίγουροι ότι θέλετε να αναθέσετε ως παραλήπτη: ${salePoint[0].customer_name}  για την εντολή επιστροφής με αριθμό : ${this.requestId}?`,
        title: 'Ανάθεση εντολής',
        itemToExecute: {salePointId: salePoint[0].id, commandId: this.requestId}
      }
    });
  }
  selectRow(row: any) {
    this.selection.clear();
  }
}
