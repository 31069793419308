import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {LoadingService} from '../services/loading.service';
import {CommonService} from '../services/common.service';
import {MatDialog, MatSelectChange, MatTabChangeEvent, MatTabGroup} from '@angular/material';
import {InitializeSumsDialogComponent} from './initialize-sums-dialog/initialize-sums-dialog.component';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-refrigerator-budget',
  templateUrl: './refrigerator-budget.component.html',
  styleUrls: ['./refrigerator-budget.component.scss']
})
export class RefrigeratorBudgetComponent implements OnInit {
  @ViewChild('tabCtrl', {static: true}) tab: MatTabGroup;
  public years: any;
  public materials: any;
  public budgetSource: any;
  public selectedTabIndex: number;
  public canShow: boolean;
  public totalSum: number;
  public tabRendered = false;
  public rowSums: any = [];
  public columnSums: any = [];
  public windowWidth: number;
  public windowHeight: number;
  public tableHeight: number;
  public tableWidth: number;
  public filterUsers: FormControl;
  public countableUsers: any = [];

  constructor(public loadingService: LoadingService, private commonService: CommonService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.filterUsers = new FormControl();
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.tableHeight = this.windowHeight - 372;
    this.tableWidth = this.windowWidth - 272;
    this.canShow = false;
    this.loadingService.hide();
    this.budgetSource = [];
    this.initYears();
    this.getMaterials();
  }

  async addTab() {
    let year = parseInt(this.years[this.years.length - 1].year, 0);
    year++;
    this.loadingService.show();
    try {
      await this.commonService.insertObject('refrigerator_budget', {year});
    } catch (e) {
      console.log(e.message);
    } finally {
      this.years.push({year});
      this.loadingService.hide();
    }
  }

  private async getMaterials() {
    try {
      this.materials = await this.commonService.getList('material');
    } catch (e) {
      console.log(e);
    }
  }

  private async initYears() {
    try {
      this.years = await this.commonService.getList('refrigerator_budget');
    } catch (e) {
      this.years = [];
      console.log(e.message);
    } finally {
      this.tabRendered = true;
    }
  }

  async tabChanged($event?: MatTabChangeEvent) {
    this.countableUsers = [];
    this.canShow = false;
    const index = $event.index;
    this.budgetSource = [];
    this.columnSums = [];
    this.rowSums = [];
    this.selectedTabIndex = index;
  }

  async initTab() {
    if (!this.selectedTabIndex) {
      this.selectedTabIndex = 0;
    }
    const searchYear = parseInt(this.years[this.selectedTabIndex].year, 0);
    try {
      this.budgetSource = await this.commonService.getList(`refrigerator_budget/${searchYear}`);

      if (this.budgetSource.length > 0) {

        this.budgetSource[0].budgets.forEach(budget => {
          const modelBudget = {
            columnSum: 0
          };
          this.columnSums.push(modelBudget);
        });

        this.budgetSource.forEach(budget => {
          const modelBudget = {
            rowSum: 0
          };
          this.rowSums.push(modelBudget);
        });

        this.budgetSource.forEach(budget => {
          budget.showHeadQuarterQuantity = true;
          budget.showBackUpQuantity = true;
          budget.budgets.forEach(b => {
            b.showElement = true;
            b.differenceInQuantities = b.initial_quantity - b.remaining_quantity;
          });
        });

        this.budgetSource[0].budgets.forEach(b => {
          this.countableUsers.push({user_id: b.user_id, userName: b.user_alternate_id, showElement: true});
        });
        this.countableUsers.push({user_id: -1, userName: 'SM(HEADQ)', showElement: true});
        this.countableUsers.push({user_id: -2, userName: 'Διεύθυνση πωλήσεων', showElement: true});

        this.filterUsers.setValue(this.countableUsers);

        this.calculateColumnSums();
        this.calculateRowSums();

        this.totalSum = this.getTotalSum();
        this.canShow = true;
      }
    } catch (e) {
      this.budgetSource = [];
    } finally {
      this.canShow = true;
    }
  }

  calculateColumnSums() {
    try {
      this.columnSums.forEach((sum, index) => {
        let columnSummary = 0;

        this.budgetSource.forEach((budget, position) => {
          columnSummary = columnSummary + parseInt(budget.budgets[index].initial_quantity, 0);
        });
        sum.columnSum = columnSummary;
      });
    } catch (e) {
      throw e;
    }
  }

  calculateRowSums() {
    try {
      this.rowSums.forEach((sum, index) => {
        let rowSummary = 0;
        this.budgetSource[index].budgets.forEach(budget => {
          if (budget.showElement) {
            rowSummary = rowSummary + parseInt(budget.initial_quantity, 0);
          }
        });
        if (this.budgetSource[index].showHeadQuarterQuantity) {
          rowSummary = rowSummary + this.budgetSource[index].headq_quantity;
        }
        if (this.budgetSource[index].showBackUpQuantity) {
          rowSummary = rowSummary + this.budgetSource[index].backup_quantity;
        }
        sum.rowSum = rowSummary;
        this.budgetSource[index].total_quantity = rowSummary;
      });
    } catch (e) {
      throw e;
    }
  }

  backUpSum() {
    let sum = 0;
    this.budgetSource.forEach(budget => {
      sum = sum + budget.backup_quantity;
    });

    return sum;
  }


  getTotalSum(): number {
    let sum = 0;
    this.budgetSource.forEach(budget => {
      sum = sum + budget.total_quantity;
    });
    return sum;

  }

  async updateData(index: any, user?: any) {
    this.budgetSource[index].total_quantity = parseInt(this.budgetSource[index].total_quantity, 0);
    this.totalSum = this.getTotalSum();
    this.calculateDifferences();
    this.calculateColumnSums();
    this.calculateRowSums();
    try {
      this.loadingService.show();
      if (user.user_id) {
        await this.commonService.updateObject('refrigerator_budget', {
          id: user.id, initial_quantity: user.initial_quantity,
          remaining_quantity: user.remaining_quantity, total_budget_id: this.budgetSource[index].total_budget_id
        });
      } else {
        let model = {};
        if (user === 'backup_quantity') {
          model = {
            backup_quantity: this.budgetSource[index].backup_quantity,
            total_budget_id: this.budgetSource[index].total_budget_id
          };
        } else {
          model = {
            headq_quantity: this.budgetSource[index].headq_quantity,
            total_budget_id: this.budgetSource[index].total_budget_id
          };
        }
        await this.commonService.updateObject('refrigerator_budget', model);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      this.loadingService.hide();
    }
  }

  calculateDifferences() {
    this.budgetSource.forEach(budget => {
      budget.budgets.forEach(b => {
        b.remaining_quantity = b.initial_quantity - b.differenceInQuantities;
      });
    });
  }

  check($event?: MatSelectChange) {
    const userList = [];
    this.countableUsers.forEach(cu => {
      const exists = this.filterUsers.value.some(user => user.user_id === cu.user_id);
      if (!exists) {
        userList.push({user_id: cu.user_id, userName: cu.userName});
      }
    });

    userList.forEach(inActive => {
      this.countableUsers.forEach(u => {
        if (inActive.user_id === u.user_id) {
          u.showElement = false;
        }
        if (inActive.user_id === -1) {
          u.showElement = false;
        }
        if (inActive.user_id === -2) {
          u.showElement = false;
        }
      });
      this.budgetSource.forEach(budget => {
        if (inActive.user_id === -1) {
          budget.showHeadQuarterQuantity = false;
        }
        if (inActive.user_id === -2) {
          budget.showBackUpQuantity = false;
        }
        budget.budgets.forEach(b => {
          if (b.user_id === inActive.user_id) {
            b.showElement = false;
          }
        });
      });
    });

    this.filterUsers.value.forEach(activeUser => {
      this.countableUsers.forEach(u => {
        if (activeUser.user_id === u.user_id) {
          u.showElement = true;
        }
        if (activeUser.user_id === -1) {
          u.showElement = true;
        }
        if (activeUser.user_id === -2) {
          u.showElement = true;
        }
      });
      this.budgetSource.forEach(budget => {
        if (activeUser.user_id === -1) {
          budget.showHeadQuarterQuantity = true;
        }
        if (activeUser.user_id === -2) {
          budget.showBackUpQuantity = true;
        }
        budget.budgets.forEach(b => {
          if (b.user_id === activeUser.user_id) {
            b.showElement = true;
          }
        });
      });
    });

    this.calculateRowSums();
    this.totalSum = this.getTotalSum();
  }

  canShowElement(i: number) {
    return this.countableUsers[i].showElement;
  }
}
